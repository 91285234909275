<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="breadcrumb-wrapper"><span class="text-muted fw-light">Issues /</span> Create</h4>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-3">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="mb-0" v-if="status">New Issue</h4>
                        <h4 class="mb-0" v-else>Update Issue</h4>
                    </div>
                    <form class="card-body" @submit.prevent="submitForm()">
                        <div class="row mb-3">
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Date <span class="star">*</span> 
                                </label>
                                <input class="form-control" type="date" placeholder="Date" v-model="issue.issue_date" :class="{ 'is-invalid': errors.issue_date }" ref="issue_date" disabled="disable"/>
                                <span v-if="errors.issue_date" class="invalid-feedback">{{ errors.issue_date[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label"> Name <span class="star">*</span>  </label>
                                <input class="form-control" type="text" :class="{ 'is-invalid': errors.name }" placeholder="Name" v-model="issue.name" />
                                <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Email <span class="star">*</span></label>
                                <div class="input-group input-group-merge">
                                    <input type="text" class="form-control" :class="{'is-invalid': errors.email}" v-model="issue.email" placeholder="Email" disabled="disable" />
                                    <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Mobile No <span class="star">*</span> </label>
                                <input class="form-control" type="text" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile No" v-model="issue.mobile_no" />
                                <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                            </div>
                            <h5 class="mb-2">Observations</h5>
                            
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">
                                    Lot No 
                                    <span class="star" v-if="$store.getters.user?.role?.role_name=='PL'">*</span>
                                </label>
                                <input class="form-control" type="text" :class="{'is-invalid': errors.lot_no}" placeholder="Lot No" v-model="issue.lot_no" />
                                <span v-if="errors.lot_no" class="invalid-feedback">{{ errors.lot_no[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Date-Week-Month-Year <span class="star">*</span></label>
                                <input class="form-control" type="text" :class="{'is-invalid': errors.batch_no}" placeholder="Date-Week-Month-Year" v-model="issue.batch_no" />
                                <span v-if="errors.batch_no" class="invalid-feedback">{{ errors.batch_no[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Depot Name <span class="star">*</span></label>
                                <search 
                                    :class="{ 'is-invalid': errors.depot_id }" 
                                    :customClass="{ 'is-invalid': errors.depot_id }" 
                                    :initialize="issue.depot_id" 
                                    id="depot_id"
                                    label="depot_name"
                                    placeholder="Select Depot Name"
                                    :data="depots"
                                    @input="depot => issue.depot_id = depot">
                                </search>
                                <span v-if="errors.depot_id" class="invalid-feedback">{{ errors.depot_id[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Mode of Transportation <span class="star">*</span></label>
                                <select class="form-select" v-model="issue.transportation_mode_id" :class="{'is-invalid': errors.transportation_mode_id}">
                                    <option value="">Select Mode of Transportation</option>
                                    <option v-for="(transportation_mode,key) in transportation_modes" :key="key" :value="transportation_mode.transportation_mode_id">
                                        {{ transportation_mode.transportation_mode_name}}
                                    </option>
                                </select>
                                <span v-if="errors.transportation_mode_id" class="invalid-feedback">{{ errors.transportation_mode_id[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3" v-if="$store.getters.user?.role?.role_name=='PL'">
                                <label class="form-label">Invoice No <span class="star">*</span></label>
                                <input class="form-control" type="text" :class="{'is-invalid': errors.invoice_no}" placeholder="Invoice No" v-model="issue.invoice_no" />
                                <span v-if="errors.invoice_no" class="invalid-feedback">{{ errors.invoice_no[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Bag Source Plant <span class="star">*</span></label>
                                <search 
                                    :class="{ 'is-invalid': errors.plant_id }" 
                                    :customClass="{ 'is-invalid': errors.plant_id }" 
                                    :initialize="issue.plant_id" 
                                    id="plant_id"
                                    label="plant_name"
                                    placeholder="Select Plant Name"
                                    :data="plants"
                                    :disabled="$store.getters.user?.role?.role_name=='PL'"
                                    @input="selectPlant($event)">
                                </search>
                                <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Type of Bag <span class="star">*</span></label>
                                <select class="form-select" v-model="issue.bag_type_id" :class="{'is-invalid': errors.bag_type_id}">
                                    <option value="">Select Type of Bag</option>
                                    <option v-for="(bag_type,key) in bag_types" :key="key" :value="bag_type.bag_type_id">
                                        {{ bag_type.bag_type_name}}
                                    </option>
                                </select>
                                <span v-if="errors.bag_type_id" class="invalid-feedback">{{ errors.bag_type_id[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Bag Grade / SKU <span class="star">*</span></label>
                               <select class="form-select" v-model="issue.grade_id" :class="{'is-invalid': errors.grade_id}">
                                    <option value="">Select Bag Grade / SKU</option>
                                    <option v-for="(grade,key) in grades" :key="key" :value="grade.grade_id">
                                        {{ grade.grade_name}}
                                    </option>
                                </select>
                                <span v-if="errors.grade_id" class="invalid-feedback">{{ errors.grade_id[0] }}</span>
                            </div>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Mode of Handling <span class="star">*</span></label>
                                <select class="form-select" v-model="issue.handling_mode_id" :class="{'is-invalid': errors.handling_mode_id}">
                                    <option value="">Select Mode of Handling</option>
                                    <option v-for="(handling_mode,key) in handling_modes" :key="key" :value="handling_mode.handling_mode_id">
                                        {{ handling_mode.handling_mode_name}}
                                    </option>
                                </select>
                                <span v-if="errors.handling_mode_id" class="invalid-feedback">{{ errors.handling_mode_id[0] }}</span>
                            </div>
                            <h5 class="mb-2">Nature Of Observation</h5>
                            <div class="col-sm-3 mb-3">
                                <label class="form-label">Nature of Observation <span class="star">*</span></label>
                                <select class="form-select" v-model="issue.complaint_id" :class="{'is-invalid': errors.complaint_id}">
                                    <option value="">Select Nature of Observation</option>
                                    <option v-for="(complaint,key) in complaints" :key="key" :value="complaint.complaint_id">
                                        {{ complaint.complaint_name}}
                                    </option>
                                </select>
                                <span v-if="errors.complaint_id" class="invalid-feedback">{{ errors.complaint_id[0] }}</span>
                            </div>
                            <div class="col-sm-9 mb-3">
                                <label class="form-label">Details Of Observation (10 to 150 words) <span class="star">*</span></label>
                                <input class="form-control" type="text" :class="{'is-invalid': errors.observation_details}" placeholder="Details of Observation" v-model="issue.observation_details" />
                                <span v-if="errors.observation_details" class="invalid-feedback">{{ errors.observation_details[0] }}</span>
                            </div>
                            <h5 class="mb-2">Attachments</h5>
                            <div class="col-sm-4 mb-3">
                                <label class="form-label">Upload Vendor's Logo <span class="star">*</span></label>
                                <input type="file" class="form-control" :class="{'is-invalid': errors.logo}" @change="handleFileUpload($event,'Logo')" ref="logo" />
                                <span class="form-text">Max Size : 10MB, Format : png, jpg, jpeg</span>
                                <span v-if="errors.logo" class="invalid-feedback">{{ errors.logo[0] }}</span>
                            </div>
                            <div class="col-sm-4 mb-3">
                                <label class="form-label">Upload Images <span class="star">*</span></label>
                                <input type="file" class="form-control" :class="{'is-invalid': errors.image}" @change="handleFileUpload($event,'Image')" ref="image"/>
                                <span class="form-text">Max Size : 10MB, Format : png, jpg, jpeg</span>
                                <span v-if="errors.image" class="invalid-feedback">{{ errors.image[0] }}</span>
                            </div>
                            <div class="col-sm-4 mb-3">
                                <label class="form-label">Upload Video <span class="star">*</span></label>
                                <input type="file" class="form-control" :class="{'is-invalid': errors.video}" @change="handleFileUpload($event,'Video')" ref="video"/>
                                <span class="form-text">Max Size : 10MB, Format : mp4</span>
                                <span v-if="errors.video" class="invalid-feedback">{{ errors.video[0] }}</span>
                            </div>
                            <div  v-for="(issue_attachment,key) in issue.issue_attachments" :key="key">
                                <div class="ms-auto row d-flex">
                                    <div class="col-sm-4 mb-3">
                                        <a target="_blank" :href="issue_attachment.attachment_path"><span class="badge bg-label-danger">{{issue_attachment.attachment_name}}</span></a>
                                    </div>
                                </div>
                            </div>
                            <h5 class="mb-2">Scale of Issue</h5>
                            <div class="col-sm-6 mb-3">
                                <label class="form-label">No. of Bags Received</label>
                                <input class="form-control" type="number" placeholder="No. of Bags Received" v-model="issue.no_of_bags_received" :class="{ 'is-invalid': errors.no_of_bags_received }" />
                                <span v-if="errors.no_of_bags_received" class="invalid-feedback">{{ errors.no_of_bags_received[0] }}</span>
                            </div>
                            <div class="col-sm-6 mb-3">
                                <label class="form-label">No. of Bags With Issue</label>
                                <input class="form-control" type="number" placeholder="No. of Bags With Issue" v-model="issue.no_of_bags_with_issue" />
                                <span v-if="errors.no_of_bags_with_issue" class="invalid-feedback">{{ errors.no_of_bags_with_issue[0] }}</span>
                                <span v-if="issue.no_of_bags_received < issue.no_of_bags_with_issue" style="color:red;">* Kindly enter valid Number of bags with issues</span>
                            </div>
                            <div class="col-sm-12">
                                <div class="alert alert-warning" role="alert">
                                    <div>* Observation should be supported with valid supporting documents as mention below.</div>
                                    <div>* Clear photographs / videos along with plant address, vendor logo & Lot No to identify the nature and severity of issue</div>
                                </div>
                            </div>
                        </div>
                        <div class="float-end">
                            <router-link to="/issues" class="btn btn-label-danger me-2">Cancel</router-link>
                            <button type="submit" v-if="status" class="btn btn-primary">Submit</button>
                            <button type="submit" v-else class="btn btn-primary">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    let Search = require('@/components/Search.vue').default;
    export default {
        components: {Search},
        data() {
            return {
                status: true,
                issue: {
                    issue_id: "",
                    issue_no:"",
                    issue_date: moment().format('YYYY-MM-DD'),
                    issue_from:"",
                    ttsm_id:"",
                    name:"",
                    email:"",
                    role:"",
                    mobile_no:"",
                    grade_id:"",
                    lot_no:"",
                    batch_no:"",
                    depot_id:"",
                    transportation_mode_id:"",
                    invoice_no:'',
                    plant_id:"",
                    bag_type_id:"",
                    handling_mode_id:"",
                    complaint_id:"",
                    observation_details:"",
                    no_of_bags_received:"",
                    no_of_bags_with_issue:"",
                    logo:"",
                    image:"",
                    video:"",
                },
                grades:[],
                depots:[],
                transportation_modes:[],
                plants:[],
                bag_types:[],
                handling_modes:[],
                complaints:[],
                issue_attachments:[],
                errors: [],
            };
        },
        
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.issue.ttsm_id = vm.$store.getters?.user.user_id;
                vm.issue.issue_from = vm.$store.getters?.user?.role?.role_name;
                vm.issue.name = vm.$store.getters?.user.name;
                vm.issue.email = vm.$store.getters?.user.email;
                vm.issue.mobile_no = vm.$store.getters?.user.mobile_no;
                vm.issue.role = vm.$store.getters.user?.role?.role_name;
                if(vm.$store.getters?.user?.role?.role_name=='PL') {
                    vm.issue.plant_id = vm.$store.getters.user.plant_id;
                    vm.getGrades();
                }
                vm.getDepots()
                if (to.name == "issues.create") {
                    vm.$refs.issue_date.focus();
                } 
                else {
                    vm.status = false;
                    
                    vm.issue.issue_id = from.params.issue_id;
                    let uri = { uri: "issues/ttsm/show", data: vm.issue };
                    vm.$store.dispatch("post", uri).then(function (response) {
                        vm.issue = response.data.data;
                        vm.issue.name = vm.$store.getters?.user.name;
                        vm.issue.email = vm.$store.getters?.user.email;
                        vm.issue.mobile_no = vm.$store.getters?.user.mobile_no;
                        vm.issue.role = vm.$store.getters.user?.role?.role_name;

                        vm.issue.issue_attachments = response.data.data.issue_attachments;
                        vm.getGrades();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },
        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            getDepots() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getDepots" })
                .then(function (response) {
                    vm.depots = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },


            getDepots() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getDepots" })
                .then(function (response) {
                    vm.depots = response.data.data;
                    vm.getTransportationModes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getTransportationModes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getTransportationModes" })
                .then(function (response) {
                    vm.transportation_modes = response.data.data;
                    vm.getPlants()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getPlants() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data;
                    vm.getBagTypes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getBagTypes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getBagTypes" })
                .then(function (response) {
                    vm.bag_types = response.data.data;
                    vm.getHandlingModes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getHandlingModes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getHandlingModes" })
                .then(function (response) {
                    vm.handling_modes = response.data.data;
                    vm.getComplaints()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getComplaints() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getComplaints" })
                .then(function (response) {
                    vm.complaints = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            handleFileUpload(e, attachment_type) {
                e.preventDefault();
                let vm = this;
                if(attachment_type=='Logo') {
                    vm.issue.logo = vm.$refs.logo.files[0]
                }
                else if(attachment_type=='Image') {
                    vm.issue.image = vm.$refs.image.files[0]
                }
                else {
                    vm.issue.video = vm.$refs.video.files[0]
                }
            },

            create() {
                let vm = this;
                if(vm.issue.no_of_bags_received < vm.issue.no_of_bags_with_issue) {
                    alert('Kindly enter valid Number of bags with issues')
                }
                else {
                    let loader = vm.$loading.show();
                    let formData = new FormData();
                    formData.append("issue_date", vm.issue.issue_date);
                    formData.append("issue_from", vm.issue.issue_from);
                    formData.append("ttsm_id", vm.issue.ttsm_id);
                    formData.append("name", vm.issue.name);
                    formData.append("email", vm.issue.email);
                    formData.append("mobile_no", vm.issue.mobile_no);
                    formData.append("role", vm.issue.role);
                    formData.append("grade_id", vm.issue.grade_id);
                    formData.append("lot_no", vm.issue.lot_no);
                    formData.append("batch_no", vm.issue.batch_no);
                    formData.append("depot_id", vm.issue.depot_id);
                    formData.append("transportation_mode_id", vm.issue.transportation_mode_id);
                    formData.append("invoice_no", vm.issue.invoice_no);
                    formData.append("plant_id", vm.issue.plant_id);
                    formData.append("bag_type_id", vm.issue.bag_type_id);
                    formData.append("handling_mode_id", vm.issue.handling_mode_id);
                    formData.append("complaint_id", vm.issue.complaint_id);
                    formData.append("observation_details", vm.issue.observation_details);
                    formData.append("no_of_bags_received", vm.issue.no_of_bags_received);
                    formData.append("no_of_bags_with_issue", vm.issue.no_of_bags_with_issue);
                    formData.append("logo", vm.issue.logo);
                    formData.append("image", vm.issue.image);
                    formData.append("video", vm.issue.video);
                    
                    vm.$store.dispatch("post", { uri: "issues/ttsm", data: formData })
                    .then(function (response) {
                        loader.hide();
                        vm.$store.commit("setUser", response.data.data);
                        vm.$store.dispatch("success", "issue is successfully Created");
                        vm.$router.push("/issues");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },


            selectPlant(e) {
                let vm = this;
                vm.issue.plant_id = e;
                vm.getGrades()
            },

            getGrades() {
                let vm = this;
                 
                vm.$store.dispatch("post", { uri: "getGrades", data:vm.issue })
                .then(function (response) {
                    vm.grades = response.data.data;
                })
               
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            update() {
                let vm = this;
                if(vm.issue.no_of_bags_received < vm.issue.no_of_bags_with_issue) {
                    alert('Kindly enter valid Number of bags with issues')
                }
                else {
                    let loader = vm.$loading.show();
                    let formData = new FormData();

                    formData.append("role", vm.issue.role);
                    formData.append("issue_id", vm.issue.issue_id);
                    formData.append("issue_date", vm.issue.issue_date);
                    formData.append("issue_from", vm.issue.issue_from);
                    formData.append("ttsm_id", vm.issue.ttsm_id);
                    formData.append("grade_id", vm.issue.grade_id);
                    formData.append("lot_no", vm.issue.lot_no);
                    formData.append("batch_no", vm.issue.batch_no);
                    formData.append("depot_id", vm.issue.depot_id);
                    formData.append("transportation_mode_id", vm.issue.transportation_mode_id);
                    formData.append("invoice_no", vm.issue.invoice_no);
                    formData.append("plant_id", vm.issue.plant_id);
                    formData.append("bag_type_id", vm.issue.bag_type_id);
                    formData.append("handling_mode_id", vm.issue.handling_mode_id);
                    formData.append("complaint_id", vm.issue.complaint_id);
                    formData.append("observation_details", vm.issue.observation_details);
                    formData.append("no_of_bags_received", vm.issue.no_of_bags_received);
                    formData.append("no_of_bags_with_issue", vm.issue.no_of_bags_with_issue);
                    formData.append("logo", vm.issue.logo);
                    formData.append("image", vm.issue.image);
                    formData.append("video", vm.issue.video);
                    
                    vm.$store.dispatch("post", { uri: "issues/ttsm/edit", data: formData })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Issue is successfully updated");
                        vm.$router.push("/issues");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            }
        },
    };
</script>
<style>
    .star {
        color: red;
        font-weight: bold;
    }
</style>
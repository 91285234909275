<template>
    <footer class="content-footer footer bg-footer-theme">
        <div class="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div class="mb-2 mb-md-0">
                © Copyright 2022
                , All rights reserved.
                <a href="https://akxatech.com" target="_blank" class="footer-link fw-semibold">AKXA Tech Private Limited</a>
            </div>
        </div>
    </footer>
</template>

<template>
    <figure class="highcharts-figure">
        <div id="pie-container"></div>
    </figure>
</template>

<script>
    var Highcharts = require("highcharts");
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/export-data")(Highcharts);
    require("highcharts/modules/accessibility")(Highcharts);
    export default {
        name: "LineChart",
        mounted() {
            this.loadChart();
        },

        methods: {
            loadChart() {
                Highcharts.chart('pie-container', {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: 'Issues Analysis'
                    },
                    subtitle: {
                        text:'09-11-2022'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    },
                    series: [{
                        name: 'Issues',
                        colorByPoint: true,
                        data: [{
                            name: 'Open',
                            y: 70.67,
                        }, {
                            name: 'Forward',
                            y: 14.77
                        },  {
                            name: 'Reverse',
                            y: 4.86
                        }, {
                            name: 'Reopen',
                            y: 2.63
                        }, {
                            name: 'Close',
                            y: 1.53
                        }]
                    }]
                });

            },
        },
    };
</script>

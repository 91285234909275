<template>
    <nav aria-label="Page navigation">
        <ul class="pagination pagination-lg">
            <li class="page-item first">
                <button class="page-link" @click="onClickFirstPage" :disabled="isInFirstPage">
                    <i class="tf-icon bx bx-chevrons-left"></i>
                </button>
            </li>
            <li class="page-item prev">
                <button class="page-link" @click="onClickPreviousPage" :disabled="isInFirstPage">
                    <i class="tf-icon bx bx-chevron-left"></i>
                </button>
            </li>
            <li class="page-item" v-for="(page,key) in pages"  :key="key" :class="{ active: isPageActive(page.name) }">
                <button class="page-link" @click="onClickPage(page.name)" :disabled="page.isDisabled">
                    {{ page.name }}
                </button>
            </li>
            <li class="page-item next">
                <button class="page-link" @click="onClickNextPage" :disabled="isInLastPage">
                    <i class="tf-icon bx bx-chevron-right"></i>
                </button>
            </li>
            <li class="page-item last">
                <button class="page-link" @click="onClickLastPage" :disabled="isInLastPage">
                    <i class="tf-icon bx bx-chevrons-right"></i>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "Proj-Pagination",
        props: {
            maxPage: {
                type: Number,
                required: true,
            },
            totalPages: {
                type: Number,
                required: true,
            },
            currentPage: {
                type: Number,
                required: true,
            },
        },

        computed: {
            startPage() {
                if (this.currentPage === 1) {
                    return 1;
                }
                if (this.currentPage === this.totalPages) {
                    return this.totalPages - this.maxPage + 1;
                }
                return this.currentPage - 1;
            },
            endPage() {
                return Math.min(this.startPage + this.maxPage - 1, this.totalPages);
            },
            pages() {
                const range = [];
                for (let i = this.startPage; i <= this.endPage; i += 1) {
                    range.push({
                        name: i,
                        isDisabled: i === this.currentPage,
                    });
                }
                return range;
            },
            isInFirstPage() {
                return this.currentPage === 1;
            },
            isInLastPage() {
                return this.currentPage === this.totalPages;
            },
        },
        methods: {
            onClickFirstPage() {
                this.$emit("pagechanged", 1);
            },
            onClickPreviousPage() {
                this.$emit("pagechanged", this.currentPage - 1);
            },
            onClickPage(page) {
                this.$emit("pagechanged", page);
            },
            onClickNextPage() {
                this.$emit("pagechanged", this.currentPage + 1);
            },
            onClickLastPage() {
                this.$emit("pagechanged", this.totalPages);
            },
            isPageActive(page) {
                return this.currentPage === page;
            },
        },
    };
</script>

<style>
    .width-75 {
        width: 75px;
    }
    .width-200 {
        width: 250px;
    }
    .card-header {
        padding: 1rem 1.375rem;
    }
    .pagination {
        margin-bottom: 0px !important;
    }

    table > thead > tr > th:not(.sorting_disabled),
    table > thead > tr > td:not(.sorting_disabled) {
        padding-right: 30px;
    }

    table > thead .sorting,
    table > thead .sorting_asc,
    table > thead .sorting_desc,
    table > thead .sorting_asc_disabled,
    table > thead .sorting_desc_disabled {
        cursor: pointer;
        position: relative;
    }

    table > thead .sorting:before, table > thead .sorting:after,
    table > thead .sorting_asc:before,
    table > thead .sorting_asc:after,
    table > thead .sorting_desc:before,
    table > thead .sorting_desc:after,
    table > thead .sorting_asc_disabled:before,
    table > thead .sorting_asc_disabled:after,
    table > thead .sorting_desc_disabled:before,
    table > thead .sorting_desc_disabled:after {
        position: absolute;
        bottom: 0.5em;
        display: block;
        opacity: 0.3;
    }

    table > thead .sorting:before,
    table > thead .sorting_asc:before,
    table > thead .sorting_desc:before,
    table > thead .sorting_asc_disabled:before,
    table > thead .sorting_desc_disabled:before {
        right: 1em;
        content: "↑";
    }

    table > thead .sorting:after,
    table > thead .sorting_asc:after,
    table > thead .sorting_desc:after,
    table > thead .sorting_asc_disabled:after,
    table > thead .sorting_desc_disabled:after {
        right: 0.5em;
        content: "↓";
    }

    table > thead .sorting_asc:before,
    table > thead .sorting_desc:after {
        opacity: 1;
    }

    table > thead .sorting_asc_disabled:before,
    table > thead .sorting_desc_disabled:after {
        opacity: 0;
    }
</style>

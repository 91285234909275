<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="breadcrumb-wrapper">Issues</h4>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">From Date</label>
                        <input class="form-control" type="date" v-model="meta.from_date" @change="search" />
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">To Date</label>
                        <input class="form-control" type="date" v-model="meta.to_date" @change="search" />
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Depot Name</label>
                        <search
                            :initialize="meta.depot_id" 
                            id="depot_id"
                            label="depot_name"
                            placeholder="All"
                            :data="depots"
                            @input="changeDepot($event)">
                        </search>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Mode of Transportation</label>
                        <select class="form-select" v-model="meta.transportation_mode_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(transportation_mode,key) in transportation_modes" :key="key" :value="transportation_mode.transportation_mode_id">
                                {{ transportation_mode.transportation_mode_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Bag Source Plant</label>
                        <search
                            :initialize="meta.plant_id" 
                            id="plant_id"
                            label="plant_name"
                            placeholder="All"
                            :data="plants"
                            @input="changePlant($event)"
                            :disabled="$store.getters.user?.role?.role_name=='QC' || $store.getters.user?.role?.role_name=='PL'">
                        </search>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Type of Bag</label>
                        <select class="form-select" v-model="meta.bag_type_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(bag_type,key) in bag_types" :key="key" :value="bag_type.bag_type_id">
                                {{ bag_type.bag_type_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Mode of Handling</label>
                        <select class="form-select" v-model="meta.handling_mode_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(handling_mode,key) in handling_modes" :key="key" :value="handling_mode.handling_mode_id">
                                {{ handling_mode.handling_mode_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Nature of Observation</label>
                        <select class="form-select" v-model="meta.complaint_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(complaint,key) in complaints" :key="key" :value="complaint.complaint_id">
                                {{ complaint.complaint_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Stage</label>
                        <select class="form-select" v-model="meta.stage_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(stage,key) in stages" :key="key" :value="stage.stage_id">
                                {{ stage.stage_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Status</label>
                        <select class="form-select" v-model="meta.status" @change="search">
                            <option value="">All</option>
                            <option value="Open">Open</option>
                            <option value="Close">Close</option>
                        </select>
                    </div>
                    <div class="col-sm-4 mb-2">
                        <label class="form-label">Issue No</label>
                        <div class="input-group">
                            <input class="form-control" type="text" placeholder="Search for issue no" v-model="meta.search" @keyup="search">
                            <button @click="reset()" class="btn btn-outline-danger" type="button">Reset</button>
                        </div>
                    </div>
                    <div class="col-sm-12 mb-2">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-sm table-striped table-bordered">
                                <thead class="table-dark">
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th class="sorting" @click="sortTable('issue_no')">Issue No</th>
                                        <th class="sorting" @click="sortTable('issue_date')">Issue Date</th>
                                        <th class="sorting" @click="sortTable('issue_from')">Issue From</th>
                                        <th class="sorting" @click="sortTable('depot_name')">Depot Name</th>
                                        <th>Bag Source Plant</th>
                                        <th class="sorting" @click="sortTable('complaint_name')">Observation</th>
                                        <th class="text-center">Stage</th>
                                        <th class="text-center">Status</th>
                                        <th class="sorting text-center" @click="sortTable('status')">Issue</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr v-for="(issue,key) in issues" :key="key">
                                        <td class="text-center">{{ key+1 }}</td>
                                        <td>
                                            <router-link :to="'/issues/'+issue.issue_id">
                                                {{ issue.issue_no }}
                                            </router-link>
                                        </td>
                                        <td>{{ $filters.formatDate(issue.issue_date) }}</td>
                                        <td>{{ issue.issue_from }}</td>
                                        <td>{{ issue.depot?.depot_name }}</td>
                                        <td>{{ issue.plant?.plant_name }}</td>
                                        <td>{{ issue.complaint?.complaint_name }}</td>
                                        <td class="text-center">
                                            <span class="badge me-2" :class="issue.issue_log?.stage?.color">
                                                {{ issue.issue_log?.stage?.stage_name }}
                                            </span>
                                            <span v-if="issue.issue_log?.status?.status_name!='Close'" class="badge badge-center rounded-pill bg-label-danger">
                                                {{ issue.issue_log?.days }}
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge" :class="issue.issue_log?.status?.color">
                                                {{ issue.issue_log?.status?.status_name }}
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge me-2" :class="issue.issue_color">
                                                {{ issue.issue_status }}
                                            </span>
                                            <span v-if="issue.issue_status=='Open'" class="badge badge-center rounded-pill bg-label-primary">
                                                {{ issue.issue_days }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="issues.length==0">
                                        <th class="text-center" colspan="9">No records found</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div class="d-flex align-items-center">
                                <select class="form-control width-75 me-2" v-model="meta.per_page" @change="onPerPageChange">
                                    <option>10</option>
                                    <option>15</option>
                                    <option>20</option>
                                    <option>25</option>
                                    <option>30</option>
                                </select>
                                <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                            </div>
                            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    let Search = require('@/components/Search.vue').default;
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Search, Pagination },
        data() {
            return {
                status: true,
                meta: {
                    order_by: "desc",
                    keyword: "issue_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 0,
                    to: 0,
                    maxPage: 1,
                    from_date: "",
                    to_date: "",
                    depot_id: "",
                    transportation_mode_id: "",
                    plant_id: "",
                    bag_type_id: "",
                    handling_mode_id: "",
                    complaint_id: "",
                    stage_id: "",
                    status: "",
                    search: "",
                    role_id: this.$store.getters.user.role_id,
                    user_id: this.$store.getters.user.user_id,
                },
                issues:[],
                depots:[],
                transportation_modes:[],
                plants:[],
                bag_types:[],
                handling_modes:[],
                complaints:[],
                stages:[],
                statuses:[],
            };
        },
        created() {
            let vm = this;
            if(vm.$store.getters?.user?.role?.role_name=='TTSM') {
                vm.meta.stage_id = 1;
            }
            if(vm.$store.getters?.user?.role?.role_name=='PL') {
                vm.meta.plant_id = vm.$store.getters.user.plant_id;
                vm.meta.stage_id = 1;
            }
            if(vm.$store.getters?.user?.role?.role_name=='QC') {
                vm.meta.plant_id = vm.$store.getters.user.plant_id;
                vm.meta.stage_id = 2;
            }
            if(vm.$store.getters?.user?.role?.role_name=='TPMC') {
                vm.meta.stage_id = 3;
            }
            if(vm.$store.getters?.user?.role?.role_name=='CPC') {
                vm.meta.stage_id = 4;
            }
            vm.getDepots()
            vm.index()
        },
        methods: {
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "issues", data: vm.meta })
                .then(function (response) {
                    vm.issues = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.to = response.data.meta.to;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            getDepots() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getDepots" })
                .then(function (response) {
                    vm.depots = response.data.data;
                    vm.getTransportationModes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            edit(issue) {
                this.$router.push("/issues/" + issue.issue_id + "/edit");
            },

            getTransportationModes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getTransportationModes" })
                .then(function (response) {
                    vm.transportation_modes = response.data.data;
                    vm.getPlants()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getPlants() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data;
                    vm.getBagTypes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getBagTypes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getBagTypes" })
                .then(function (response) {
                    vm.bag_types = response.data.data;
                    vm.getHandlingModes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getHandlingModes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getHandlingModes" })
                .then(function (response) {
                    vm.handling_modes = response.data.data;
                    vm.getComplaints()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getComplaints() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getComplaints" })
                .then(function (response) {
                    vm.complaints = response.data.data;
                    vm.getStages()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getStages() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getStages" })
                .then(function (response) {
                    vm.stages = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getStatuses() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getStatuses" })
                .then(function (response) {
                    vm.statuses = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            reset() {
                let vm = this;
                vm.meta.from_date = "";
                vm.meta.to_date = "";
                vm.meta.depot_id = "";
                vm.meta.transportation_mode_id = "";
                vm.meta.plant_id = "";
                vm.meta.bag_type_id = "";
                vm.meta.handling_mode_id = "";
                vm.meta.complaint_id = "";
                vm.meta.stage_id = "";
                vm.meta.status = "";
                vm.meta.search = "";
                vm.search();
            },  

            changePlant(plant_id) {
                let vm = this;
                vm.meta.plant_id = plant_id;
                vm.search();
            },

            changeDepot(depot_id) {
                let vm = this;
                vm.meta.depot_id = depot_id;
                vm.search();
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            sortTable(keyword) {
                let vm = this;
                vm.meta.keyword = keyword;
                vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc"
                vm.index();
            },
        },
    };
</script>

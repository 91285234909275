<template>
    <div class="modal fade" id="qc" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-simple modal-edit-user">
            <div class="modal-content p-3 p-md-5">
                <div class="modal-body">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="text-center mb-4">
                        <img :src="$store.getters.user?.avatar" alt="Avatar" class="rounded-circle me-3" width="54">
                        <div class="card-title mb-0">
                            <h5 class="mb-0">{{ $store.getters?.user.name }}</h5>
                            <small class="text-muted">{{ $store.getters?.user.email }} - {{ $store.getters?.user.mobile_no }}</small>
                        </div>
                    </div>
                    <form class="row g-3" @submit.prevent="submit()">
                        <div class="col-sm-6">
                                <label class="form-label">Bag Grade / SKU <span class="star">*</span></label>
                               <select class="form-select" v-model="issue.grade_id" :class="{'is-invalid': errors.grade_id}">
                                    <option value="">Select Bag Grade / SKU</option>
                                    <option v-for="(grade,key) in grades" :key="key" :value="grade.grade_id">
                                        {{ grade.grade_name}}
                                    </option>
                                </select>
                                <span v-if="errors.grade_id" class="invalid-feedback">{{ errors.grade_id[0] }}</span>
                            </div>
                        <div class="col-sm-6">
                            <label class="form-label">Lot No <span class="star">*</span></label>
                            <input class="form-control" type="text" :class="{'is-invalid': errors.lot_no}" placeholder="Lot No" v-model="issue.lot_no" />
                            <span v-if="errors.lot_no" class="invalid-feedback">{{ errors.lot_no[0] }}</span>
                        </div>
                        <div class="col-sm-6">
                            <label class="form-label">Vendor Name <span class="star">*</span></label>
                            <input class="form-control" type="text" :class="{'is-invalid': errors.vendor_name}" placeholder="Vendor Name" v-model="issue.vendor_name" />
                            <span v-if="errors.vendor_name" class="invalid-feedback">{{ errors.vendor_name[0] }}</span>
                        </div>
                        <div class="col-sm-6">
                            <label class="form-label">Material Code <span class="star">*</span></label>
                            <input class="form-control" type="text" :class="{'is-invalid': errors.material_code}" placeholder="Material Code" v-model="issue.material_code" />
                            <span v-if="errors.material_code" class="invalid-feedback">{{ errors.material_code[0] }}</span>
                        </div>
                        <div class="col-sm-12">
                            <label class="form-label">Plant QC Report <span class="star">*</span></label>
                            <input type="file" class="form-control" :class="{'is-invalid': errors.attachment}" @change="handleFileUpload($event)" ref="attachment" />
                            <span class="form-text">Max Size : 10MB, Format : png, jpeg, jpg, pdf</span>
                            <span v-if="errors.attachment" class="invalid-feedback">{{ errors.attachment[0] }}</span>
                        </div>
                        <div class="col-sm-12">
                            <label class="form-label">Any deviation found in test report vs bag specs ? <span class="star">*</span></label>
                            <select class="form-control" :class="{'is-invalid': errors.deviations}" v-model="issue.deviations">
                                <option value="">Any deviation found in test report vs bag specs ?</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <span v-if="errors.deviations" class="invalid-feedback">{{ errors.deviations[0] }}</span>
                        </div>
                        <div class="col-sm-12">
                            <label class="form-label">
                                Details of deviations if any (Text limit : 150 words ) 
                                <span v-if="issue.deviations=='Yes'" class="star">*</span>
                            </label>
                            <textarea class="form-control" :class="{'is-invalid': errors.deviation_details}" placeholder="Details of deviations if any" v-model="issue.deviation_details"></textarea>
                            <span v-if="errors.deviation_details" class="invalid-feedback">{{ errors.deviation_details[0] }}</span>
                        </div>
                         <div class="col-sm-12">
                            <div class="alert alert-warning" role="alert">
                                <div>Issue will be forwarded to TPMC .</div>
                            </div>
                        </div>
                        <div class="col-sm-12 text-center mt-4">
                            <button type="button" class="btn btn-label-secondary me-sm-3 me-1" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            data: {
                required: true
            },
            edit: {
                default:0
            }
        },
        data() {
            return {
                issue: {
                    issue_id:"",
                    qc_id:"",
                    grade_id:"",
                    lot_no:"",
                    vendor_name:"",
                    material_code:"",
                    attachment:"",
                    deviations:"",
                    deviation_details:"",
                    plant_id:""
                },
                errors: [],
                grades:[],
            };
        },
        mounted() {
            let vm = this;
            vm.issue.qc_id = vm.$store.getters?.user?.user_id;
            vm.issue.issue_id = vm.data.issue_id==null ? '' : vm.data.issue_id;
            vm.issue.grade_id = vm.data.grade_id==null ? '' : vm.data.grade_id;
            vm.issue.lot_no = vm.data.lot_no==null ? '' : vm.data.lot_no;
            vm.issue.vendor_name = vm.data.vendor_name==null ? '' : vm.data.vendor_name;
            vm.issue.material_code = vm.data.material_code==null ? '' : vm.data.material_code;
            vm.issue.attachment = vm.data.attachment==null ? '' : vm.data.attachment;
            vm.issue.deviations = vm.data.deviations==null ? '' : vm.data.deviations;
            vm.issue.deviation_details = vm.data.deviation_details==null ? '' : vm.data.deviation_details;
            vm.getGrades();
        },
        methods: {
            handleFileUpload(e) {
                e.preventDefault();
                let vm = this;
                vm.issue.attachment = vm.$refs.attachment.files[0]
            },

             getGrades() {
                let vm = this;
                vm.issue.plant_id = vm.data.plant_id;
                vm.$store.dispatch("post", { uri: "getGrades", data:vm.issue })
                .then(function (response) {
                    vm.grades = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            submit() {
                let vm = this;
                let loader = vm.$loading.show();
                let formData = new FormData();
                formData.append("issue_id", vm.issue.issue_id);
                formData.append("qc_id", vm.issue.qc_id);
                formData.append("grade_id", vm.issue.grade_id);
                formData.append("lot_no", vm.issue.lot_no);
                formData.append("vendor_name", vm.issue.vendor_name);
                formData.append("material_code", vm.issue.material_code);
                formData.append("attachment", vm.issue.attachment);
                formData.append("deviations", vm.issue.deviations);
                formData.append("deviation_details", vm.issue.deviation_details);
                formData.append("edit", vm.edit);
                vm.$store.dispatch("post", { uri: "issues/qc", data: formData })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Issue is successfully Updated");
                    location.reload();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        }
    };
</script>
import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store/index.js";

// Auth
import Login from "@/views/auth/Login.vue";

// Dashboard
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";

// Configurations
import Depot from "../views/configuration/Depot.vue";
import TransportationMode from "../views/configuration/TransportationMode.vue";
import Zone from "../views/configuration/Zone.vue";
import Plant from "../views/configuration/Plant.vue";
import Grade from "../views/configuration/Grade.vue";
import BagType from "../views/configuration/BagType.vue";
import HandlingMode from "../views/configuration/HandlingMode.vue";
import Complaint from "../views/configuration/Complaint.vue";
import User from "../views/configuration/User.vue";
import Escalation from "../views/configuration/Escalation.vue";

// Issues
import Issues from "../views/issues/Index.vue";
import NewIssue from "../views/issues/Create.vue";
import ViewIssue from "../views/issues/View.vue";

// Report
import IssueReport from "../views/report/Issue.vue";

const routes = [
	{
        path: "/", name: "Login",
        component: Login,  meta: { unAuth: true } 
    },
	{
		path: "/home", name: "home",
		component: Home, meta: { auth: true } 
	},
	{
		path: "/dashboard", name: "dashboard",
		component: Home, meta: { auth: true } 
	},
	{
		path: "/profile", name: "profile",
		component: Profile, meta: { auth: true } 
	},

	// Configurations
	{
		path: "/depots", name: "depot.index",
		component: Depot, meta: { auth: true } 
	},
	{
		path: "/transportation_modes", name: "transportation_modes.index",
		component: TransportationMode, meta: { auth: true } 
	},
	{
		path: "/zones", name: "zones.index",
		component: Zone, meta: { auth: true } 
	},
	{
        path: "/plants", name: "plants.index",
        component: Plant,  meta: { auth: true } 
    },
	{
        path: "/grades", name: "grades.index",
        component: Grade,  meta: { auth: true } 
    },
	{
		path: "/bag_types", name: "bag_types.index",
		component: BagType, meta: { auth: true } 
	},
	{
		path: "/handling_modes", name: "handling_modes.index",
		component: HandlingMode, meta: { auth: true } 
	},
	{
		path: "/complaints", name: "complaints.index",
		component: Complaint, meta: { auth: true } 
	},
	{
		path: "/users", name: "users.index",
		component: User, meta: { auth: true } 
	},	
	{
        path: "/escalations", name: "escalations.index",
        component: Escalation, meta: { auth: true } 
    },

	// Issues
	{
		path: "/issues", name: "issues.index",
		component: Issues, meta: { auth: true } 
	},
	{
		path: "/issues/create", name: "issues.create",
		component: NewIssue, meta: { auth: true } 
	},
	{
		path: "/issues/:issue_id", name: "issues.view",
		component: ViewIssue, meta: { auth: true} 
	},
	{
        path:'/issues/:issues_id/edit',
        name:"issues.edit",
        component:NewIssue,
        meta: { auth: true, permission:'issues.update' }   
    },

	// Report
	{
        path: "/report/issue",
        name: "report-issue.index",
        component: IssueReport,
        meta: { auth: true} 
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(function(to, _, next) {
	if (to.meta.auth && !store.getters.user) {
		next('/');
	} 
	else if(to.meta.unAuth && store.getters.user) {
		next('/dashboard')
	}
	else {
		next();
	}
});

export default router;





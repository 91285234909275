<template>
    <div class="modal fade" id="tpmc" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md modal-simple modal-edit-user">
            <div class="modal-content p-3 p-md-5">
                <div class="modal-body">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="text-center mb-4">
                        <img :src="$store.getters.user?.avatar" alt="Avatar" class="rounded-circle me-3" width="54">
                        <div class="card-title mb-0">
                            <h5 class="mb-0">{{ $store.getters?.user.name }}</h5>
                            <small class="text-muted">{{ $store.getters?.user.email }} - {{ $store.getters?.user.mobile_no }}</small>
                        </div>
                    </div>
                    <form class="row g-3" @submit.prevent="submit()">
                        <div class="col-sm-12">
                            <label class="form-label">Action Taken <span class="star">*</span></label>
                            <textarea class="form-control" :class="{'is-invalid': errors.tpmc_action}" placeholder="Action Taken" v-model="issue.tpmc_action"></textarea>
                            <span v-if="errors.tpmc_action" class="invalid-feedback">{{ errors.tpmc_action[0] }}</span>
                        </div>
                        <div class="col-sm-12">
                            <label class="form-label">Is the Issue resolved <span class="star">*</span></label>
                            <select class="form-control" :class="{'is-invalid': errors.issue_resolved}" v-model="issue.issue_resolved" :disabled="edit">
                                <option value="">Is the Issue resolved ?</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <span v-if="errors.issue_resolved" class="invalid-feedback">{{ errors.issue_resolved[0] }}</span>
                        </div>
                        <div class="col-sm-12" v-if="issue.issue_resolved=='No'">
                            <label class="form-label">Comment <span class="star">*</span></label>
                            <textarea class="form-control" :class="{'is-invalid': errors.tpmc_comment}" placeholder="Comment" v-model="issue.tpmc_comment"></textarea>
                            <span v-if="errors.tpmc_comment" class="invalid-feedback">{{ errors.tpmc_comment[0] }}</span>
                        </div>
                        <div class="col-sm-12">
                            <div class="alert alert-warning" role="alert">
                                <div v-if="issue.issue_resolved=='No'">Issue will be forwarded to CPC .</div>
                                <div v-if="issue.issue_resolved=='Yes'">Issue will be Closed.</div>
                            </div>
                        </div>
                        <div class="col-sm-12 text-center mt-4">
                            <button type="button" class="btn btn-label-secondary me-sm-3 me-1" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            data: {
                required: true,
            },
            edit: {
                default:0
            }
        },
        data() {
            return {
                issue: {
                    issue_id:"",
                    tpmc_id:"",
                    tpmc_action:"",
                    issue_resolved:"",
                    tpmc_comment:"",
                },
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.issue.tpmc_id = vm.$store.getters?.user?.user_id;
            vm.issue.issue_id = vm.data.issue_id==null ? '' : vm.data.issue_id;
            vm.issue.tpmc_action = vm.data.tpmc_action==null ? '' : vm.data.tpmc_action;
            vm.issue.issue_resolved = vm.data.issue_resolved==null ? '' : vm.data.issue_resolved;
            vm.issue.tpmc_comment = vm.data.tpmc_comment==null ? '' : vm.data.tpmc_comment;
        },
        methods: {
        
            submit() {
                let vm = this;
                let loader = vm.$loading.show();
                let formData = new FormData();
                formData.append("issue_id", vm.issue.issue_id);
                formData.append("tpmc_id", vm.issue.tpmc_id);
                formData.append("tpmc_action", vm.issue.tpmc_action);
                formData.append("issue_resolved", vm.issue.issue_resolved);
                formData.append("tpmc_comment", vm.issue.tpmc_comment);
                formData.append("edit", vm.edit);
                vm.$store.dispatch("post", { uri: "issues/tpmc", data: formData })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Issue is successfully Updated");
                    location.reload();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        }
    };
</script>
<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="breadcrumb-wrapper"><span class="text-muted fw-light">Configuration /</span> Grades</h4>
        <div class="row">
            <div class="col-xl-4">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="mb-0" v-if="status">New Grade</h4>
                        <h4 class="mb-0" v-else>Update Grade</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm">
                            <div class="mb-3">
                                <label class="form-label">Grade Name <span class="star">*</span></label>
                                <input class="form-control" type="text" placeholder="Grade Name" ref="grade_name" v-model="grade.grade_name" :class="{'is-invalid': errors.grade_name}" />
                                <span v-if="errors.grade_name" class="invalid-feedback">{{ errors.grade_name[0] }}</span>
                            </div>

                            <div class="mb-3">
                                <label class="form-label"> Plant <span class="star">*</span></label>
                                <search 
                                    :class="{ 'is-invalid': errors.plant_id }" 
                                    :customClass="{ 'is-invalid': errors.plant_id }" 
                                    :initialize="grade.plant_id" 
                                    id="plant_id"
                                    label="plant_name"
                                    placeholder="Select Plant Name"
                                    :data="plants"
                                    @input="plant => grade.plant_id = plant">
                                </search>
                                <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Description</label>
                                <textarea class="form-control" type="text" placeholder="Description" v-model="grade.description"></textarea>
                                <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                            </div>
                            <div class="float-end">
                                <button type="button" @click="discard" class="btn btn-label-danger me-2">Cancel</button>
                                <button type="submit" v-if="status" class="btn btn-primary">Submit</button>
                                <button type="submit" v-else class="btn btn-success">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-xl-8">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="mb-0">Grades</h4>
                        <input class="form-control width-200" type="text" placeholder="Search" @keyup="search" v-model="meta.search" />
                    </div>
                    <div class="card-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-sm table-striped table-bordered">
                                <thead class="table-dark">
                                    <tr>
                                        <th class="text-center">#</th>
                                        
                                        <th class="sorting" @click="sortTable('grade_name')">Grade Name</th>
                                        <th class="sorting" @click="sortTable('plant_id')">Plant</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr v-for="(grade,key) in grades" :key="key">
                                        <td class="text-center">{{ meta.from+key }}</td>
                                        <td>{{ grade.grade_name }}</td>
                                        <td>{{ grade.plant.plant_name }}</td>   
                                        <td class="text-center">
											<div class="form-switch">
												<input class="form-check-input" type="checkbox" v-model="grade.status" :id="grade.grade_id" @change="deleteGrade(grade)" />
											</div>
										</td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm btn-icon btn-label-success me-1" @click="editGrade(grade)" title="Edit">
                                                <i class="bx bx-edit-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="grades.length==0">
                                        <th class="text-center" colspan="6">No records found</th>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>Grade Name</th>
                                        <th>Plant</th>
                                        <th class="text-center">Description</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <select class="form-control width-75" v-model="meta.per_page" @change="onPerPageChange">
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                            </select>
                            <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    let Search = require('@/components/Search.vue').default;
    export default {
        components: { Pagination, Search },
        data() {
            return {
                status: true,
                grade: {
                    grade_id: "",
                    grade_name: "",
                    plant_id: "",
                    description: "",
                },
                errors: [],
                plants: [],
                grades: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "grade_name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                },
            };
        },
        mounted() {
            let vm = this;
            vm.discard();
            vm.getPlants();
        },
        methods: {
            discard() {
                let vm = this;
                vm.$refs.grade_name.focus();
                vm.grade.grade_name = "";
                vm.grade.plant_id = "";
                vm.grade.description = "";
                vm.status = true;
                vm.errors = [];
                vm.index();
            },

            getPlants() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateGrades", data: vm.meta })
                .then(function (response) {
                    vm.grades = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.to = response.data.meta.to;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            submitForm() {
				let vm = this;
				if(vm.status) {
					vm.addGrade()
				}
				else {
					vm.updateGrade()
				}
			},

            addGrade() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "addGrade", data: vm.grade })
                .then(function (response) {
                    vm.grades = response.data.data;
                    loader.hide();
                    vm.$store.dispatch("success", "Grade Added successfully");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            editGrade(grade) {
                let vm = this;
                vm.grade = grade;
                vm.status = false;
                vm.$refs.grade_name.focus();
            },

            updateGrade() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateGrade", data: vm.grade };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Grade is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteGrade(grade) {
                let vm = this;
                vm.$store.dispatch("post", { uri: "deleteGrade", data: grade })
                .then(function () {
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            sortTable(keyword) {
                let vm = this;
                vm.meta.keyword = keyword;
                vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc";
                vm.index();
            }
        }
    };
</script>

<template>
    <aside id="layout-menu" class="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0 d-block d-sm-none">
        <div class="container-xxl d-flex h-100">
            <ul class="menu-inner py-1">
                
            </ul>
        </div>
    </aside>
</template>
<script>
    export default {
        name: "Menu",
    };
</script>
<style scoped>
 .bg-menu-theme {
        background-color: #1c222f !important;
        color: #a1b0cb;
    }
</style>
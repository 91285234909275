<template>
    <div class="container-xxl flex-grow-1 container-p-y" v-if="issue">
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="breadcrumb-wrapper"><span class="text-muted fw-light">Issues /</span> {{ issue.issue_no }}</h4>
        </div>
        <div class="row">
            <div class="col-xl-8">
                <div class="card mb-4">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center me-3">
                            <img :src="issue?.user?.avatar" alt="Avatar" class="rounded-circle me-3" width="54" />
                            <div class="card-title mb-0">
                                <h5 class="mb-0">{{ issue?.user?.name }}</h5>
                                <small class="text-muted">{{ issue?.user?.email }} - {{ issue?.user?.mobile_no }}</small>
                            </div>
                        </div>
                        <span class="badge bg-primary">
                            Role : {{ issue?.user?.role?.role_name }}
                        </span>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-sm table-striped">
                                <tbody class="table-border-bottom-0">
                                    <tr>
                                        <th width="10%">Issue No</th>
                                        <td>: {{ issue.issue_no }}</td>
                                        <th width="10%">Issue Date</th>
                                        <td>: {{ $filters.formatDate(issue.issue_date) }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Bag Grade/SKU</th>
                                        <td>: {{ issue?.grade?.grade_name }}</td>
                                        <th width="10%">Lot No</th>
                                        <td>: {{ issue.lot_no }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Date-Week-Month-Year on bag</th>
                                        <td>: {{ issue.batch_no }}</td>
                                        <th width="10%">Depot Name</th>
                                        <td>: {{ issue?.depot?.depot_name }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Mode of Transportation</th>
                                        <td>: {{ issue?.transportation_mode?.transportation_mode_name }}</td>
                                        <th width="10%">Bag Source Plant</th>
                                        <td>: {{ issue?.plant?.plant_name }}</td>
                                    </tr>
                                    <tr v-if="issue.issue_from=='PL'">
                                        <th width="10%">Invoice No</th>
                                        <td colspan="3">: {{ issue?.invoice_no }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Type of Bag</th>
                                        <td>: {{ issue?.bag_type?.bag_type_name }}</td>
                                        <th width="10%">Mode of Handling</th>
                                        <td>: {{ issue?.handling_mode?.handling_mode_name }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Nature of Observation</th>
                                        <td colspan="3">: {{ issue?.complaint?.complaint_name }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Details of the Observations</th>
                                        <td colspan="3">: {{ issue.observation_details }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">No of Bags Received</th>
                                        <td>: {{ issue.no_of_bags_received }}</td>
                                        <th width="10%">No of Bags with Issue</th>
                                        <td>: {{ issue.no_of_bags_with_issue }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer border-top" v-if="issue?.issue_log?.stage?.stage_name=='TTSM | PL' && ($store.getters?.user?.role?.role_name=='TTSM' || $store.getters?.user?.role?.role_name=='PL')">
                        <div class="float-end">
                            <router-link :to="'/issues/'+issue.issue_id+'/edit'" class="btn btn-success">Edit</router-link>
                        </div>
                    </div>
                    <div class="card-footer border-top" v-if="issue.qc_id==null && issue?.issue_log?.stage?.stage_name=='QC' && $store.getters?.user?.role?.role_name=='QC'">
                        <div class="float-end">
                            <button type="button" @click="updateStage(2,1)" data-bs-toggle="modal" data-bs-target="#reverse" class="btn btn-label-danger me-2">Reverse</button>
                            <button type="button" data-bs-toggle="modal" data-bs-target="#field" class="btn btn-info me-2">Request Data</button>
                            <button type="button" data-bs-toggle="modal" data-bs-target="#qc" class="btn btn-primary">Forward</button>
                            <QC v-if="status" :data="issue" :edit="0"></QC>
                        </div>
                    </div>
                </div>

                <div class="card mb-4" v-if="issue.qc_id!=null">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center me-3">
                            <img :src="issue?.qc?.avatar" alt="Avatar" class="rounded-circle me-3" width="54" />
                            <div class="card-title mb-0">
                                <h5 class="mb-0">{{ issue?.qc?.name }}</h5>
                                <small class="text-muted">{{ issue?.qc?.email }} - {{ issue?.qc?.mobile_no }}</small>
                            </div>
                        </div>
                        <span class="badge bg-primary">
                            Role : {{ issue?.qc?.role?.role_name }}
                        </span>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-sm table-striped">
                                <tbody class="table-border-bottom-0">
                                    <tr>
                                        <th width="10%">Bag Grade/SKU</th>
                                        <td>: {{ issue?.grade?.grade_name }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Lot No</th>
                                        <td>: {{ issue.lot_no }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Vendor Name</th>
                                        <td>: {{ issue.vendor_name }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Material Code</th>
                                        <td>: {{ issue.material_code }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Is there any deviations ?</th>
                                        <td>: {{ issue.deviations }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Deviation Details</th>
                                        <td>: {{ issue.deviation_details }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer border-top" v-if="issue?.issue_log?.stage?.stage_name=='QC' && $store.getters?.user?.role?.role_name=='QC'">
                        <div class="float-end">
                            <button type="button" data-bs-toggle="modal" data-bs-target="#qc" class="btn btn-success">Edit</button>
                        </div>
                        <QC v-if="status" :edit="1" :data="issue"></QC>
                    </div>
                    <div class="card-footer border-top" v-if="issue.tpmc_id==null && issue?.issue_log?.stage?.stage_name=='TPMC' && $store.getters?.user?.role?.role_name=='TPMC'">
                        <div class="float-end">
                            <button type="button" @click="updateStage(3,2)" data-bs-toggle="modal" data-bs-target="#reverse" class="btn btn-label-danger me-2">Reverse</button>
                            <button type="button" data-bs-toggle="modal" data-bs-target="#tpmc" class="btn btn-primary">Forward</button>
                            <TPMC v-if="status" :data="issue" :edit="0"></TPMC>
                        </div>
                    </div>
                </div>

                <div class="card mb-4" v-if="issue.tpmc_id!=null">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center me-3">
                            <img :src="issue?.tpmc?.avatar" alt="Avatar" class="rounded-circle me-3" width="54" />
                            <div class="card-title mb-0">
                                <h5 class="mb-0">{{ issue?.tpmc?.name }}</h5>
                                <small class="text-muted">{{ issue?.tpmc?.email }} - {{ issue?.tpmc?.mobile_no }}</small>
                            </div>
                        </div>
                        <span class="badge bg-primary">
                            Role : {{ issue?.tpmc?.role?.role_name }}
                        </span>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-sm table-striped">
                                <tbody class="table-border-bottom-0">
                                    <tr>
                                        <th width="10%">Action Taken</th>
                                        <td>: {{ issue.tpmc_action }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Is the Issue resolved ?</th>
                                        <td>: {{ issue.issue_resolved }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Comment</th>
                                        <td>: {{ issue.tpmc_comment }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer border-top" v-if="issue.issue_status=='Open' && issue?.issue_log?.stage?.stage_name=='TPMC' && $store.getters?.user?.role?.role_name=='TPMC'">
                        <div class="float-end">
                            <button type="button" data-bs-toggle="modal" data-bs-target="#tpmc" class="btn btn-success">Edit</button>
                        </div>
                        <TPMC v-if="status" :edit="1" :data="issue"></TPMC>
                    </div>
                    <div class="card-footer border-top" v-if="issue.cpc_id==null && issue?.issue_log?.stage?.stage_name=='CPC' && $store.getters?.user?.role?.role_name=='CPC'">
                        <div class="float-end">
                            <button type="button" @click="updateStage(4,3)" data-bs-toggle="modal" data-bs-target="#reverse" class="btn btn-label-danger me-2">Reverse</button>
                            <button type="button" data-bs-toggle="modal" data-bs-target="#cpc" class="btn btn-primary">Submit</button>
                            <CPC v-if="status" :data="issue"></CPC>
                        </div>
                    </div>
                </div>

                <div class="card mb-4" v-if="issue.cpc_id!=null">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center me-3">
                            <img :src="issue?.cpc?.avatar" alt="Avatar" class="rounded-circle me-3" width="54" />
                            <div class="card-title mb-0">
                                <h5 class="mb-0">{{ issue?.cpc?.name }}</h5>
                                <small class="text-muted">{{ issue?.cpc?.email }} - {{ issue?.cpc?.mobile_no }}</small>
                            </div>
                        </div>
                        <span class="badge bg-primary">
                            Role : {{ issue?.cpc?.role?.role_name }}
                        </span>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-sm table-striped">
                                <tbody class="table-border-bottom-0">
                                    <tr>
                                        <th width="10%">Action Taken</th>
                                        <td>: {{ issue.cpc_action }}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">Closed Date</th>
                                        <td>: {{ $filters.formatDate(issue.close_date) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="mb-0">Attachments</h5>
                    </div>
                    <div class="card-body">
                        <ul class="list-unstyled mb-0">
                            <li class="mb-3" v-for="(issue_attachment,key) in issue.issue_attachments" :key="key">
                                <div class="d-flex align-items-center">
                                    <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                            <img src="assets/img/icons/unicons/cube.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                            <h6 class="mb-0"><span v-if="issue_attachment.attachment_type=='Logo'">Vendor's </span>{{ issue_attachment.attachment_type }}</h6>
                                            <small class="text-muted">{{ issue_attachment.attachment_name }}</small>
                                        </div>
                                    </div>
                                    <div class="ms-auto">
                                        <a type="button" data-bs-toggle="modal" data-bs-target="#attachment" @click="openAttachment(issue_attachment)">
                                            <span class="badge bg-label-danger">open</span>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="mb-0">Activities</h5>
                    </div>
                    <div class="card-body">
                        <ul class="timeline">
                            <li class="timeline-item timeline-item-transparent ps-4" v-for="(issue_log,key) in issue.issue_logs" :key="key">
                                <span class="timeline-point timeline-point-primary"></span>
                                <div class="timeline-event pb-2">
                                    <div class="timeline-header mb-1">
                                        <h6 class="mb-0">
                                            Stage :
                                            <span class="badge me-2" :class="issue_log?.stage?.color">
                                                {{ issue_log?.stage?.stage_name }}
                                            </span>
                                            Status :
                                            <span class="badge me-2" :class="issue_log?.status?.color">
                                                {{ issue_log?.status.status_name }}
                                            </span>
                                        </h6>
                                    </div>
                                    <div>
                                        <small class="text-muted">Date & Time : {{ issue_log.created_at }}</small>
                                    </div>
                                    <div>
                                        <span v-if="issue_log.remark!=null">Remark : {{ issue_log?.remark }}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="field" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-md modal-simple modal-edit-user">
                <div class="modal-content p-3 p-md-5">
                    <div class="modal-body">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="text-center mb-4">
                            <img :src="$store.getters.user?.avatar" alt="Avatar" class="rounded-circle me-3" width="54" />
                            <div class="card-title mb-0">
                                <h5 class="mb-0">{{ $store.getters?.user.name }}</h5>
                                <small class="text-muted">{{ $store.getters?.user.email }} - {{ $store.getters?.user.mobile_no }}</small>
                            </div>
                        </div>
                        <form class="row g-3" @submit.prevent="requestData()">
                            <div class="col-sm-12">
                                <label class="form-label">MM Department Emails <span class="star">*</span></label>
                                <input type="text" class="form-control" :class="{'is-invalid': errors.mm_department_emails}" placeholder="MM Department Emails" v-model="log.mm_department_emails" />
                                <span v-if="errors.mm_department_emails" class="invalid-feedback">{{ errors.mm_department_emails[0] }}</span>
                            </div>
                            <div class="col-sm-12 text-center mt-4">
                                <button type="reset" class="btn btn-label-secondary me-sm-3 me-1" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="reverse" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-md modal-simple modal-edit-user">
                <div class="modal-content p-3 p-md-5">
                    <div class="modal-body">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="text-center mb-4">
                            <img :src="$store.getters.user?.avatar" alt="Avatar" class="rounded-circle me-3" width="54" />
                            <div class="card-title mb-0">
                                <h5 class="mb-0">{{ $store.getters?.user.name }}</h5>
                                <small class="text-muted">{{ $store.getters?.user.email }} - {{ $store.getters?.user.mobile_no }}</small>
                            </div>
                        </div>
                        <form class="row g-3" @submit.prevent="reverseIssue()">
                            <div class="col-sm-12">
                                <label class="form-label">Stage</label>
                                <select class="select2 form-select select2-hidden-accessible" v-model="log.to_stage_id" :class="{'is-invalid': errors.to_stage_id}">
                                    <option value="">Select Stage</option>
                                    <option v-for="(stage,key) in stages" :key="key" :value="stage.stage_id">{{ stage.stage_name}}</option>
                                </select>
                                <span v-if="errors.to_stage_id" class="invalid-feedback">{{ errors.to_stage_id[0] }}</span>
                            </div>
                            <div class="col-sm-12">
                                <label class="form-label">Remarks (Text limit 150 words)<span class="star">*</span></label>
                                <textarea class="form-control" :class="{'is-invalid': errors.remark}" placeholder="Remarks" v-model="log.remark"></textarea>
                                <span v-if="errors.remark" class="invalid-feedback">{{ errors.remark[0] }}</span>
                            </div>
                            <div class="col-sm-12">
                                <div class="alert alert-warning" role="alert">
                                    <div v-if="log.to_stage_id == 1">Issue will be reversed to TTSM | PL .</div>
                                    <div v-if="log.to_stage_id == 2">Issue will be reversed to QC .</div>
                                    <div v-if="log.to_stage_id == 3">Issue will be reversed to TPMC .</div>
                                </div>
                            </div>
                            <div class="col-sm-12 text-center mt-4">
                                <button type="button" class="btn btn-label-secondary me-sm-3 me-1" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="attachment" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <iframe :src="attachment"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import QC from "@/components/QC.vue";
    import TPMC from "@/components/TPMC.vue";
    import CPC from "@/components/CPC.vue";
    export default {
        components: { QC, TPMC, CPC },
        data() {
            return {
                status: false,
                log: {
                    user_id: this.$store.getters.user.user_id,
                    role_id: this.$store.getters.user.role_id,
                    issue_id: "",
                    from_stage_id: "",
                    to_stage_id: "",
                    remark: "",
                    mm_department_emails: "",
                },
                issue: {},
                stages: [],
                errors: [],
                attachment: ""
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.issue.issue_id = to.params.issue_id;
                let loader = vm.$loading.show();
                let uri = { uri: "showIssue", data: vm.issue };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.issue = response.data.data;
                        vm.log.issue_id = vm.issue.issue_id;
                        vm.status = true;
                        vm.getStages();
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            });
        },
        methods: {
            updateStage(from_stage_id, to_stage_id) {
                let vm = this;
                vm.log.from_stage_id = from_stage_id;
                vm.log.to_stage_id = to_stage_id;
            },

            reverseIssue() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "issues/reverse", data: vm.log })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Issue is successfully Updated");
                        location.reload();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getStages() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "getOtherStages", data: vm.log })
                    .then(function (response) {
                        loader.hide();
                        vm.stages = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            requestData() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "issues/mmemail", data: vm.log };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Mail has been successfully sent to Material Department Team");
                        location.reload();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            openAttachment(issue_attachment) {
                this.attachment = issue_attachment.attachment_path
            }
        },
    };
</script>

<style scoped>
    iframe {
        width: 75%;
        height: 400px;
        text-align: center;
    }

    @media (min-width: 576px)
    {
        .modal-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
}
    }

    .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.5rem;
    text-align: center;
}

</style>

<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="breadcrumb-wrapper"><span class="text-muted fw-light">Configuration /</span> Escalation Matrix</h4>
        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Escalation Matrix</h4>
            </div>
            <div class="card-body">
                <div class="table-responsive text-nowrap">
                    <table class="table table-sm table-striped table-bordered">
                        <thead class="table-dark">
                            <tr>
                                <th class="text-center">#</th>
                                <th>Stage</th>
                                <th>Warning (Days)</th>
                                <th>Escalation (Days)</th>
                                <th>Warning Notification To</th>
                                <th>Escalation Notification To</th>
                            </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                            <tr v-for="(escalation,key) in escalations" :key="key">
                                <td class="text-center">{{ meta.from+key }}</td>
                                <td>{{ escalation.stage.stage_name }}</td>
                                <td>
                                    <input class="form-control" type="text" placeholder="Warning" v-model="escalation.warning" />
                                </td>
                                <td>
                                    <input class="form-control" type="text" placeholder="Escalation" v-model="escalation.escalation" />
                                </td>
                                <td>
                                    <div>
                                        <select class="select2 form-select select2-hidden-accessible" v-model="escalation.warning_notification_to" :class="{'is-invalid': errors.warning_notification_to}">
                                            <option value="">Warning Escalation To</option>
                                            <option value="TTSM Head">TTSM Head</option>
                                            <option value="QC">QC</option>
                                            <option value="QC Head">QC Head</option>
                                            <option value="TPMC">TPMC</option>
                                            <option value="TPMC Head">TPMC Head</option>
                                            <option value="CPC">CPC</option>
                                            <option value="CPC Head">CPC Head</option>
                                        </select>
                                        <span v-if="errors.warning_notification_to" class="invalid-feedback">{{ errors.warning_notification_to[0] }}</span>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <select class="select2 form-select select2-hidden-accessible" v-model="escalation.escalation_notification_to" :class="{'is-invalid': errors.escalation_notification_to}">
                                            <option value="">Escalation Notification To</option>
                                            <option value="TTSM Head">TTSM Head</option>
                                            <option value="QC">QC</option>
                                            <option value="QC Head">QC Head</option>
                                            <option value="TPMC">TPMC</option>
                                            <option value="TPMC Head">TPMC Head</option>
                                            <option value="CPC">CPC</option>
                                            <option value="CPC Head">CPC Head</option>
                                        </select>
                                        <span v-if="errors.escalation_notification_to" class="invalid-feedback">{{ errors.escalation_notification_to[0] }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="escalations.length==0">
                                <th colspan="6" class="text-center">No records found</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="float-end mt-3">
                    <button class="float-end btn btn-primary" @click="updateEscalation()">Update</button>
                </div>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Emails</h4>
                <input type="text" style="margin-left:10px" v-model="meta.search" @keyup="search" class="form-control">
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12">
                        <table class="table table-sm table-striped table-bordered">
                            <thead class="table-dark">
                                <tr>
                                    <th>Role</th>
                                    <th>Plant</th>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select class="select2 form-select select2-hidden-accessible" v-model="config.config_name" :class="{'is-invalid': errors.config_name}">
                                            <option value="">Select Role</option>
                                            <option value="TTSM Head">TTSM Head</option>
                                            <option value="QC">QC</option>
                                            <option value="QC Head">QC Head</option>
                                            <option value="TPMC">TPMC</option>
                                            <option value="TPMC Head">TPMC Head</option>
                                            <option value="CPC">CPC</option>
                                            <option value="CPC Head">CPC Head</option>
                                        </select>
                                        <span v-if="errors.config_name" class="invalid-feedback">{{ errors.config_name[0] }}</span>
                                    </td>

                                    <td>
                                        <select class="select2 form-select" v-model="config.plant_id" :class="{'is-invalid': errors.plant_id}">
                                            <option value="">Select Plant</option>
                                            <option v-for="(plant,key) in plants" :key="key" :value="plant.plant_id">{{ plant.plant_name}}</option>
                                        </select>
                                        <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                                    </td>

                                    <td>
                                        <input class="form-control" :class="{'is-invalid': errors.email}" type="text" v-model="config.email" placeholder="Email" />
                                        <div v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</div>
                                    </td>
                                    <td class="text-center text-nowrap">
                                        <button v-if="config.status" class="btn btn-outline-success" type="button" @click="addRow()">
                                            <i class="bx bx-plus"></i>
                                        </button>
                                        <button v-else class="btn btn-outline-success" type="button" @click="updateRow(config)">
                                            <i class="bx bx-redo"></i>
                                        </button>
                                    </td>
                                </tr>

                                <tr v-for="(config, key) in configs" :key="key">
                                    <td>
                                        {{ config.config_name }}
                                    </td>
                                    <td>
                                        {{ config.plant?.plant_name }}
                                    </td>
                                    <td>
                                        {{ config.email }}
                                    </td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-sm btn-icon btn-label-success me-1" title="Edit" @click="editRow(config)">
                                            <i class="bx bx-edit-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-icon btn-label-danger me-1" @click="deleteRow(config)" title="Delete">
                                            <i class="bx bx-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="configs.length==0">
                                    <th colspan="6" class="text-center">No records found</th>
                                </tr>
                            </tbody>
                        </table>
                          <div class="d-flex justify-content-between align-items-center mt-3">
                            <select class="form-control width-75" v-model="meta.per_page" @change="onPerPageChange">
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                            </select>
                            <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import Pagination from "@/components/Pagination.vue";
    export default {
          components: { Pagination },
        data() {
            return {
                
                status: true,
                escalation: {
                    escalation_id: "",
                    stage_id: "",
                    warning: "",
                    escalation: "",
                    warning_notification_to: "",
                    escalation_notification_to: "",
                },

                config: {
                    config_id: "",
                    config_name: "",
                    plant_id: "",
                    email: "",
                    status: true,
                },
                plants: [],
                errors: [],
                escalations: [],
                configs: [],
                delete_configs: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "stage_id",
                    config_keyword: "config_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                },
            };
        },
        mounted() {
            this.getEscalation();
            this.index();
        },
        methods: {

            getEscalation() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getEscalations", data: vm.meta })
                    .then(function (response) {
                        vm.escalations = response.data.data;
                        vm.getPlants()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getPlants() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },


            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateConfigs", data: vm.meta })
                .then(function (response) {
                    vm.configs = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.to = response.data.meta.to;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            updateEscalation() {
                let vm = this;
                let loader = vm.$loading.show();
                let data = {
                    escalations: vm.escalations,
                };
                let uri = { uri: "updateEscalation", data: data };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Escalation is successfully updated");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addRow() {
                let vm = this;
                let uri = { uri: "addConfig", data: vm.config };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    vm.$store.dispatch("success", "Config is successfully created");
                    vm.discard();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            editRow(config, key) {
                let vm = this;
                vm.config = config;
                vm.config.status = false;
                vm.config.key = key;
            },

            updateRow(config) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateConfig", data: config };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Config is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteRow(config) {
                if (confirm("Do you want to delete... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    vm.$store.dispatch("post", { uri: "deleteConfig", data: config })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Config is successfully deleted");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            discard() {
                let vm = this;
                vm.config.status = true;
                vm.config.config_name = "";
                vm.config.plant_id = "";
                vm.config.email = "";
                vm.errors = [];
                vm.index()
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            sortTable(keyword) {
                let vm = this;
                vm.meta.keyword = keyword;
                vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc";
                vm.index();
            },
        },
    };
</script>

<template>
    <div class="authentication-wrapper authentication-basic container-p-y">
        <div class="authentication-inner py-4">
            <div class="card">
                <div class="card-body">
                    <div class="app-brand justify-content-center">
                        <a href="index.html" class="app-brand-link gap-2">
                            <span class="logo">
                                <img src="@/assets/logo.jpg" alt="Logo" height="100" />
                            </span>
                        </a>
                    </div>
                    <div class="text-center">
                        <h4 class="mb-2">Ultra Tech Cement Limited</h4>
                        <p class="mb-4">Please sign-in to your account and start the session</p>
                    </div>
                    <form class="mb-3" @submit.prevent="login">
                        <div class="mb-3">
                            <label for="username" class="form-label">Email</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" :class="{'is-invalid': errors.email}" v-model="user.username" placeholder="Email" autofocus tabindex="1" :disabled="flag" />
                                <span class="input-group-text" id="email" v-if="user.domain">{{user.domain}}</span>
                                <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                            </div>
                        </div>
                        <div v-if="flag" class="mb-3 form-password-toggle">
                            <div class="d-flex justify-content-between">
                                <label class="form-label" for="password">OTP</label>
                                <a href="#">
                                    <small @click="generateOTP()">Resend OTP ?</small>
                                </a>
                            </div>
                            <div class="input-group input-group-merge">
                                <input type="password" class="form-control" placeholder="Enter your otp" :class="{'is-invalid': errors.otp}" v-model="user.otp" tabindex="2" />
                                <span v-if="errors.otp" class="invalid-feedback">{{ errors.otp[0] }}</span>
                            </div>
                        </div>

                        <div class="mb-3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="remember-me" />
                                <label class="form-check-label" for="remember-me"> Remember Me </label>
                            </div>
                        </div>
                        <div class="mb-3" v-if="flag">
                            <button class="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                        </div>
                        <div class="mb-3" v-else>
                            <button class="btn btn-primary d-grid w-100" type="submit">Generate OTP</button>
                        </div>
                        <!-- <div class="mb-3 text-center">
                            <h3 class="text-danger">Staging Server</h3>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                flag: false,
                user: {
                    username: "",
                    email: "",
                    domain:"@adityabirla.com",
                    otp: "",
                },
                errors: [],
            };
        },
        methods: {
            login() {
                let vm = this;
                if(vm.flag) {
                    vm.validateOtp()
                } 
                else {
                    vm.generateOTP()
                }
            },

            generateOTP() {
                let vm = this;
                vm.user.email = vm.user.username + vm.user.domain;
                console.log(vm.user.email);
                let loader = vm.$loading.show();
                let uri = { uri: "generateOTP", data: vm.user };
               
                vm.$store.dispatch("post", uri).then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "OTP sent successfully");
                     vm.flag = true;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            validateOtp() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.user.email = vm.user.username + vm.user.domain;
                loader.hide();
                vm.$store.dispatch("auth", { uri: "validateOTP", data: vm.user })
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "Successful Login");
                    vm.$store.commit("setUser", response.data['user']);
                    vm.$store.commit("setToken", response.data['token']);
                    location.reload()
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
        },
    };
</script>

<template>
    <figure class="highcharts-figure">
        <div id="line-container"></div>
    </figure>
</template>

<script>
    var Highcharts = require("highcharts");
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/export-data")(Highcharts);
    require("highcharts/modules/accessibility")(Highcharts);
    export default {
        name: "LineChart",
        mounted() {
            this.loadChart();
        },

        methods: {
            loadChart() {
                Highcharts.chart('line-container', {

                title: {
                    text: 'Issues Analysis'
                },
                subtitle: {
                    text:''
                },

                yAxis: {
                    title: {
                        text: 'Number of Issues'
                    }
                },

                xAxis: {
                    categories:["30-10-22","31-10-22","01-11-22","02-11-22","03-11-22","04-11-22","05-11-22","06-11-22","07-11-22","08-11-22","09-11-22"]
                },

                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },

                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        },
                    }
                },

                series: [{
                    name: 'Open',
                    data: [10, 11, 12, 5, 7, 10, 3, 5, 11, 12, 15]
                }, 
                {
                    name: 'Close',
                    data: [5, 7, 8, 3, 6, 7, 10, 3, 12, 10, 8]
                }],

                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }

                });
            },
        },
    };
</script>

<template>
    <div class="modal fade" id="cpc" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md modal-simple modal-edit-user">
            <div class="modal-content p-3 p-md-5">
                <div class="modal-body">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="text-center mb-4">
                        <img :src="$store.getters.user?.avatar" alt="Avatar" class="rounded-circle me-3" width="54">
                        <div class="card-title mb-0">
                            <h5 class="mb-0">{{ $store.getters?.user.name }}</h5>
                            <small class="text-muted">{{ $store.getters?.user.email }} - {{ $store.getters?.user.mobile_no }}</small>
                        </div>
                    </div>
                    <form class="row g-3" @submit.prevent="submit()">
                        <div class="col-sm-12">
                            <label class="form-label">Action Taken <span class="star">*</span></label>
                            <textarea class="form-control" :class="{'is-invalid': errors.cpc_action}" placeholder="Action Taken" v-model="issue.cpc_action"></textarea>
                            <span v-if="errors.cpc_action" class="invalid-feedback">{{ errors.cpc_action[0] }}</span>
                        </div>
                        <div class="col-sm-12">
                            <label class="form-label">Report </label>
                            <input type="file" class="form-control" :class="{'is-invalid': errors.attachment}" @change="handleFileUpload($event)" ref="attachment" />
                            <span class="form-text">Max Size : 10MB, Format : png, jpeg, jpg, pdf</span>
                            <span v-if="errors.attachment" class="invalid-feedback">{{ errors.attachment[0] }}</span>
                        </div>
                        <div class="col-sm-12 text-center mt-4">
                            <button type="button" class="btn btn-label-secondary me-sm-3 me-1" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            data: {
                required: true,
            }
        },
        data() {
            return {
                issue: {
                    issue_id:"",
                    cpc_id:"",
                    cpc_action:"",
                    attachment:"",
                },
                errors: [],
            };
        },
        created() {
            let vm = this;
            vm.issue.cpc_id = vm.$store.getters?.user?.user_id;
            vm.issue.issue_id = vm.data.issue_id==null ? '' : vm.data.issue_id;
            vm.issue.cpc_action = vm.data.cpc_action==null ? '' : vm.data.cpc_action;
        },
        methods: {

            handleFileUpload(e) {
                e.preventDefault();
                let vm = this;
                vm.issue.attachment = vm.$refs.attachment.files[0]
            },
        
            submit() {
                let vm = this;
                let loader = vm.$loading.show();
                let formData = new FormData();
                formData.append("issue_id", vm.issue.issue_id);
                formData.append("cpc_id", vm.issue.cpc_id);
                formData.append("cpc_action", vm.issue.cpc_action);
                formData.append("attachment", vm.issue.attachment);

                vm.$store.dispatch("post", { uri: "issues/cpc", data: formData })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Issue is successfully Updated");
                    location.reload();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        }
    };
</script>
<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="breadcrumb-wrapper">Dashboard</h4>
        <div class="row">
            <router-link to="/issues" class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-4">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="avatar avatar-md mx-auto mb-3">
                            <span class="avatar-initial rounded-circle bg-label-warning"><i class="bx bx-purchase-tag fs-3"></i></span>
                        </div>
                        <span class="d-block mb-1 text-nowrap">Open</span>
                        <h2 class="mb-0">{{open}}</h2>
                    </div>
                </div>
            </router-link>
            <router-link to="/issues" class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-4">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="avatar avatar-md mx-auto mb-3">
                            <span class="avatar-initial rounded-circle bg-label-primary"><i class="bx bx-purchase-tag fs-3"></i></span>
                        </div>
                        <span class="d-block mb-1 text-nowrap">Reopen</span>
                        <h2 class="mb-0">{{reopen}}</h2>
                    </div>
                </div>
            </router-link>
            <router-link to="/issues" class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-4">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="avatar avatar-md mx-auto mb-3">
                            <span class="avatar-initial rounded-circle bg-label-success"><i class="bx bx-purchase-tag fs-3"></i></span>
                        </div>
                        <span class="d-block mb-1 text-nowrap">Close</span>
                        <h2 class="mb-0">{{close}}</h2>
                    </div>
                </div>
            </router-link>
            <router-link v-if="user.role_id!=1 && user.role_id!=2 && user.role_id!=7" to="/issues" class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-4">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="avatar avatar-md mx-auto mb-3">
                            <span class="avatar-initial rounded-circle bg-label-danger"><i class="bx bx-purchase-tag fs-3"></i></span>
                        </div>
                        <span class="d-block mb-1 text-nowrap">Total Open</span>
                        <h2 class="mb-0">{{total_open}}</h2>
                    </div>
                </div>
            </router-link>
            <router-link v-if="user.role_id!=1 && user.role_id!=2 && user.role_id!=7" to="/issues" class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-4">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="avatar avatar-md mx-auto mb-3">
                            <span class="avatar-initial rounded-circle bg-label-success"><i class="bx bx-purchase-tag fs-3"></i></span>
                        </div>
                        <span class="d-block mb-1 text-nowrap">Total Close</span>
                        <h2 class="mb-0">{{total_close}}</h2>
                    </div>
                </div>
            </router-link>
            <div v-if="user.role_id!=1 && user.role_id!=7" class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-4">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="avatar avatar-md mx-auto mb-3">
                            <span class="avatar-initial rounded-circle bg-label-danger"><i class="bx bx-purchase-tag fs-3"></i></span>
                        </div>

                        <span class="d-block mb-1 text-nowrap">Escalation</span>
                        <h2 class="mb-0">0</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-sm-8">
                <div class="card">
                    <div class="card-body">
                        <LineChart></LineChart>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="card">
                    <div class="card-body">
                        <PieChart></PieChart>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
    import LineChart from "@/components/highcharts/LineChart.vue";
    import PieChart from "@/components/highcharts/PieChart.vue";
    export default {
        components: { LineChart, PieChart },
        data() {
            return {
                stage: "",
                user:{
                    user_id:this.$store.getters.user.user_id,
                    role_id:this.$store.getters.user.role_id,
                    plant_id:this.$store.getters.user.plant_id
                },
                open:0,
                close:0,
                reopen:0,
                total_open:0,
                total_close:0,
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.count();
        },
        methods: {
            count() {
                let vm = this;
                let loader = vm.$loading.show();
                
                let uri = { uri: "count", data: vm.user };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.open = response.data.open;
                    vm.reopen = response.data.reopen;
                    vm.close = response.data.close;
                    vm.total_open = response.data.total_open;
                    vm.total_close = response.data.total_close;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

        },
    };
</script>

<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="breadcrumb-wrapper">Report</h4>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">From Date</label>
                        <input class="form-control" type="date" v-model="meta.from_date" @change="search" />
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">To Date</label>
                        <input class="form-control" type="date" v-model="meta.to_date" @change="search" />
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Depot Name</label>
                        <select class="form-select" v-model="meta.depot_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(depot,key) in depots" :key="key" :value="depot.depot_id">
                                {{ depot.depot_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Mode of Transportation</label>
                        <select class="form-select" v-model="meta.transportation_mode_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(transportation_mode,key) in transportation_modes" :key="key" :value="transportation_mode.transportation_mode_id">
                                {{ transportation_mode.transportation_mode_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Bag Source Plant</label>
                        <select class="form-select" v-model="meta.plant_id" @change="search" :disabled="$store.getters?.user?.role?.role_name=='QC' || $store.getters?.user?.role?.role_name=='PL'">
                            <option value="">All</option>
                            <option v-for="(plant,key) in plants" :key="key" :value="plant.plant_id">
                                {{ plant.plant_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Type of Bag</label>
                        <select class="form-select" v-model="meta.bag_type_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(bag_type,key) in bag_types" :key="key" :value="bag_type.bag_type_id">
                                {{ bag_type.bag_type_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Mode of Handling</label>
                        <select class="form-select" v-model="meta.handling_mode_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(handling_mode,key) in handling_modes" :key="key" :value="handling_mode.handling_mode_id">
                                {{ handling_mode.handling_mode_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Nature of Observation</label>
                        <select class="form-select" v-model="meta.complaint_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(complaint,key) in complaints" :key="key" :value="complaint.complaint_id">
                                {{ complaint.complaint_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Stage</label>
                        <select class="form-select" v-model="meta.stage_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(stage,key) in stages" :key="key" :value="stage.stage_id">
                                {{ stage.stage_name}}
                            </option>
                        </select>
                    </div>

                    


                    <div class="col-sm-2 mb-2">
                        <label class="form-label">Status</label>
                        <select class="form-select" v-model="meta.status" @change="search">
                            <option value="">All</option>
                            <option value="Open">Open</option>
                            <option value="Close">Close</option>
                        </select>
                    </div>

                    <div class="col-sm-2">
                        <label class="form-label">Zone</label>
                        <select class="form-select" v-model="meta.zone_id" @change="search">
                            <option value="">All</option>
                            <option v-for="(zone,key) in zones" :key="key" :value="zone.zone_id">
                                {{ zone.zone_name}}
                            </option>
                        </select>
                    </div>

                    <div class="col-sm-2">
                    <!-- <a target="_blank"
                                        :href="$store.getters.url+'report?from_date='+meta.from_date+'&to_date='+meta.to_date+'&display_type=pdf'"
                                        class="btn btn-md btn-success" style="margin-left:5px;margin-top:20px;">
                                        <i class="fa fa-file-pdf"></i> PDF
                                    </a> -->
                     <a target="_blank"
                                        :href="$store.getters.url+'report?from_date='+meta.from_date+'&to_date='+meta.to_date+'&display_type=excel&depot_id='+meta.depot_id+'&transportation_mode_id='+meta.transportation_mode_id+'&plant_id='+meta.plant_id+'&bag_type_id='+meta.bag_type_id+'&handling_mode_id='+meta.handling_mode_id+'&complaint_id='+meta.complaint_id+'&stage_id='+meta.stage_id+'&status='+meta.status+'&role_id='+meta.role_id+'&user_id='+meta.user_id"
                                        class="btn btn-md btn-primary" style="margin-left:15px;margin-top:20px;">
                                        <i class="fa fa-file-excel"></i> Excel
                                    </a>
                    <button  type="button" @click="reset()"  class="btn btn-md btn-danger" style="margin-top:20px;margin-left:15px;">
                        <i class="fa fa-file-pdf"></i>
                        Reset
                    </button>
                    </div>

                   
                    
                    <div class="col-sm-12 mb-2">
                       <div class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item" :src="meta.source" width="100%" height="400" @ok="getData()"  ></iframe>
                                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

        data() {
            return {
                status: true,
                meta: {
                    from_date:new Date(new Date().getTime() - (15 * 24 * 60 * 60 * 1000)).toISOString().slice(0,10),
                    to_date:new Date().toISOString().slice(0,10),
                    depot_id: "",
                    transportation_mode_id: "",
                    plant_id: "",
                    bag_type_id: "",
                    handling_mode_id: "",
                    complaint_id: "",
                    stage_id: "",
                    status: "",
                    search: "",
                    zone_id: "",
                    role_id: this.$store.getters.user.role_id,
                    user_id: this.$store.getters.user.user_id,
                    display_type:'display',
                    source:''
                },
                issues:[],
                depots:[],
                transportation_modes:[],
                plants:[],
                bag_types:[],
                handling_modes:[],
                complaints:[],
                stages:[],
                statuses:[],
                zones:[]
            };
        },
        created() {
            let vm = this;
            if(vm.$store.getters?.user?.role?.role_name=='PL') {
                vm.meta.plant_id = vm.$store.getters.user.plant_id
            }
            if(vm.$store.getters?.user?.role?.role_name=='QC') {
                vm.meta.plant_id = vm.$store.getters.user.plant_id
            }
            if(vm.$store.getters?.user?.role?.role_name=='TPMC') {
                vm.meta.stage_id = 3
            }
            if(vm.$store.getters?.user?.role?.role_name=='CPC User') {
                vm.meta.stage_id = 4
            }
            vm.getData();
            vm.getDepots()
        },
        methods: {
            getDepots() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getDepots" })
                .then(function (response) {
                    vm.depots = response.data.data;
                    vm.getTransportationModes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            edit(issue) {
                this.$router.push("/issues/" + issue.issue_id + "/edit");
            },

            getTransportationModes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getTransportationModes" })
                .then(function (response) {
                    vm.transportation_modes = response.data.data;
                    vm.getPlants()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getPlants() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data;
                    vm.getBagTypes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getBagTypes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getBagTypes" })
                .then(function (response) {
                    vm.bag_types = response.data.data;
                    vm.getHandlingModes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            

            getHandlingModes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getHandlingModes" })
                .then(function (response) {
                    vm.handling_modes = response.data.data;
                    vm.getComplaints()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getComplaints() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getComplaints" })
                .then(function (response) {
                    vm.complaints = response.data.data;
                    vm.getStages()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getStages() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getStages" })
                .then(function (response) {
                    vm.stages = response.data.data;
                    vm.getStatuses()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getStatuses() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getStatuses" })
                .then(function (response) {
                    vm.statuses = response.data.data;
                    vm.getZones()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getZones() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getZones" })
                .then(function (response) {
                    vm.zones = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            sortTable(keyword) {
                let vm = this;
                vm.meta.keyword = keyword;
                vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc"
                vm.index();
            },

            changeType(display_type) {
                let vm = this;      
                vm.meta.display_type=display_type;
                vm.getData();

            },

             getData() {
                let vm = this;
                let source = vm.$store.state.url+"report?from_date=" + vm.meta.from_date +
                '&to_date=' + vm.meta.to_date + 
                '&depot_id=' + vm.meta.depot_id +
                '&transportation_mode_id=' + vm.meta.transportation_mode_id +
                '&plant_id=' +  vm.meta.plant_id+
                '&user_id=' +  vm.meta.user_id+
                '&role_id=' +  vm.meta.role_id+
                '&bag_type_id=' + vm.meta.bag_type_id+
                '&handling_mode_id=' + vm.meta.handling_mode_id+
                '&complaint_id=' + vm.meta.complaint_id+
                '&stage_id=' + vm.meta.stage_id+
                '&status=' + vm.meta.status+
                '&zone_id=' + vm.meta.zone_id+
                '&display_type=' + vm.meta.display_type;
                vm.meta.source = source;
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.getData();
            },
            reset() {
                let vm = this;
                vm.meta.from_date = new Date().toISOString().slice(0, 10),
                vm.meta.to_date = new Date().toISOString().slice(0, 10),
                vm.meta.depot_id='',
                vm.meta.transportation_mode_id='',
                vm.meta.plant_id='',
                vm.meta.bag_type_id='',
                vm.meta.handling_mode_id='',
                vm.meta.complaint_id='',
                vm.meta.stage_id= '',
                vm.meta.status='',
                vm.meta.zone_id='',
                vm.getData();
            },
        },
    };
</script>

<template>
	<div class="container-xxl" v-if="!$store.getters.user">
		<router-view />
	</div>
	<div class="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu" v-else>
		<div class="layout-container">
			<Header></Header>
			<div class="layout-page">
				<div class="content-wrapper">
					<Menu></Menu>
					<router-view />
					<Footer></Footer>
					<div class="content-backdrop fade"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import Header from '@/components/Header.vue'
	import Menu from '@/components/Menu.vue'
	import Footer from '@/components/Footer.vue'

    export default {
        name: "App",
        components: { Header, Menu, Footer },

        created() {
            let main = document.createElement("script");
            main.setAttribute("src", "assets/js/main.js");
            document.head.appendChild(main);

            if(sessionStorage.getItem("user") ) {
                this.$store.commit('setUser', JSON.parse(sessionStorage.getItem("user")))
                sessionStorage.removeItem('user')
            }
			if(sessionStorage.getItem("token") ) {
                this.$store.commit('setToken', sessionStorage.getItem("token"))
                sessionStorage.removeItem('token')
            }
            window.addEventListener("beforeunload",()=>{
                sessionStorage.setItem("user",  JSON.stringify(this.$store.getters.user))
				sessionStorage.setItem("token",  this.$store.getters.token)
            })
        },
    };
</script>
<style>
    .menu-vertical {
        flex-direction: column;
        width: 17rem !important;
    }

    .nav-link {
        color: #fff !important;
    }
</style>
<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="breadcrumb-wrapper"><span class="text-muted fw-light">Configuration /</span> Users</h4>
        <div class="row">
            <div class="col-xl-5">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="mb-0" v-if="status">New User</h4>
                        <h4 class="mb-0" v-else>Update User</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm">
                            <div class="row mb-3">
                                <div class="col-sm-6 mb-2">
                                    <label class="form-label">Name <span class="star">*</span></label>
                                    <input class="form-control" type="text" placeholder="Name" v-model="user.name" :class="{ 'is-invalid': errors.name }" ref="name" />
                                    <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                </div>
                                <div class="col-sm-6 mb-2">
                                    <label class="form-label">Email <span class="star">*</span></label>
                                    <input class="form-control" type="text" placeholder="Email" v-model="user.email" :class="{ 'is-invalid': errors.email }" />
                                    <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                </div>
                                <div class="col-sm-6 mb-2">
                                    <label class="form-label">Mobile No <span class="star">*</span> </label>
                                    <input class="form-control" type="number" placeholder="Mobile No." v-model="user.mobile_no" :class="{'is-invalid': errors.mobile_no}" />
                                    <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                </div>
                                <div class="col-sm-6 mb-2">
                                    <label class="form-label">Role <span class="star">*</span></label>
                                    <select class="select2 form-select select2-hidden-accessible" v-model="user.role_id" :class="{'is-invalid': errors.role_id}">
                                        <option value="">Select Role</option>
                                        <option v-for="(role,key) in roles" :key="key" :value="role.role_id">{{ role.role_name}}</option>
                                    </select>
                                    <span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
                                </div>
                                <div class="col-sm-6 mb-2">
                                    <label class="form-label">Is Admin ? <span class="star">*</span></label>
                                    <select class="select2 form-select select2-hidden-accessible" v-model="user.is_admin" :class="{'is-invalid': errors.is_admin}">
                                        <option value="">Select One</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    <span v-if="errors.is_admin" class="invalid-feedback">{{ errors.is_admin[0] }}</span>
                                </div>
                                <div class="col-sm-6 mb-2">
                                    <label class="form-label">Plant <span v-if="user?.role_id == 2 || user?.role_id == 7" class="star">*</span></label>
                                    <select class="select2 form-select" v-model="user.plant_id" :class="{'is-invalid': errors.plant_id}">
                                        <option value="">Select Plant</option>
                                        <option v-for="(plant,key) in plants" :key="key" :value="plant.plant_id">{{ plant.plant_name}}</option>
                                    </select>
                                    <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                                </div>
                                <div class="col-sm-12 mb-2">
                                    <label class="form-label">Zone <span v-if="user?.role_id == 4" class="star">*</span></label>
                                    <select class="select2 form-select" multiple v-model="user.zones" :class="{'is-invalid': errors.zones}">
                                        <option v-for="(zone,key) in zones" :key="key" :value="zone.zone_id">
                                            {{ zone.zone_name}}
                                        </option>
                                    </select>
                                    <span v-if="errors.zones" class="invalid-feedback">{{ errors.zones[0] }}</span>
                                </div>
                            </div>
                            <div class="float-end">
                                <button type="button" @click="discard" class="btn btn-label-danger me-2">Cancel</button>
                                <button type="submit" v-if="status" class="btn btn-primary">Submit</button>
                                <button type="submit" v-else class="btn btn-success">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-xl-7">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="mb-0">Users</h4>
                        <input class="form-control width-200" type="text" placeholder="Search" @keyup="search" v-model="meta.search" />
                    </div>
                    <div class="card-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-sm table-striped table-bordered">
                                <thead class="table-dark">
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th class="sorting" @click="sortTable('name')">Name</th>
                                        <th class="sorting" @click="sortTable('email')">Email</th>
                                        <th class="sorting text-center" @click="sortTable('mobile_no')">Mobile No</th>
                                        <th class="sorting text-center" @click="sortTable('role_id')">Role</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr v-for="(user,key) in users" :key="key">
                                        <td class="text-center">{{ meta.from+key }}</td>
                                        <td>{{ user.name }}</td>
                                        <td>{{ user.email }}</td>
                                        <td>{{ user.mobile_no }}</td>
                                        <td>{{ user.role?.role_name }}</td>
										<td class="text-center">
											<div class="form-switch" v-if="user.user_id!=$store.getters.user.user_id">
												<input class="form-check-input" type="checkbox" v-model="user.status" :id="user.user_id" @change="deleteUser(user)" />
											</div>
										</td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm btn-icon btn-label-success me-1" @click="editUser(user)" title="Edit">
                                                <i class="bx bx-edit-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="users.length==0">
                                        <th class="text-center" colspan="8">No records found</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <select class="form-control width-75" v-model="meta.per_page" @change="onPerPageChange">
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                            </select>
                            <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                status: true,
                user: {
                    user_id: "",
                    name: "",
                    email: "",
                    mobile_no: "",
                    role_id: "",
                    plant_id: "",
                    is_admin: "",
                    zones: [],
                    user_zones:[],
                },
                roles: [],
                plants: [],
                zones: [],
                errors: [],
                users: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                },
            };
        },
        mounted() {
			let vm = this;
            vm.discard();
            vm.getRoles();
        },
        methods: {

            discard() {
                let vm = this;
                vm.$refs.name.focus();
                vm.user.name = "";
                vm.user.email = "";
                vm.user.mobile_no = "";
                vm.user.role_id = "";
                vm.user.plant_id = "";
                vm.user.is_admin = "";
                vm.user.user_zones = [];
                vm.user.zones = [];
                vm.status = true;
                vm.errors = [];
                vm.index();
            },

            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateUsers", data: vm.meta })
				.then(function (response) {
					vm.users = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.to = response.data.meta.to;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },

            getRoles() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getRoles" })
                .then(function (response) {
                    vm.roles = response.data.data;
                    vm.getPlants();
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getPlants() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data;
                    vm.getZones();
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getZones() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getZones" })
                .then(function (response) {
                    vm.zones = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.addUser()
				}
				else {
					vm.updateUser()
				}
			},

            addUser() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "addUser", data: vm.user })
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch("success", "User is successfully created");
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },

            editUser(user) {
                let vm = this;
                vm.user = user;
                vm.user.zones = [];
                vm.status = false;
                vm.$refs.name.focus();
                vm.user.user_zones.forEach(user_zone => {
                    vm.user.zones.push(user_zone.zone_id);
                });
            },

            updateUser() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateUser", data: vm.user };
                vm.$store.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "User is successfully updated");
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },

            deleteUser(user) {
                let vm = this;
                vm.$store.dispatch("post", { uri: "deleteUser", data: user })
				.then(function () {
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            sortTable(keyword) {
                let vm = this;
                vm.meta.keyword = keyword;
                vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc";
                vm.index();
            }
        }
    };
</script>

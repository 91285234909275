import { createStore } from "vuex";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import axios from "axios";
import router from "@/router/index.js";

export default createStore({
    state: {
        url: "https://bcms.akxatech.com/",
        // url:"http://localhost/bcms/laravel/public/",
        user: null,
        token: null,
    },

    getters: {
        url(state) {
            return state.url;
        },
        user(state) {
            return state.user;
        },
        token(state) {
            return state.token;
        },
    },

    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.token = token;
        },
    },
    
    actions: {
        auth(context, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.state.url + "api/" + payload.uri, payload.data)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        },

        async logout(context) {
            await context.commit("setUser", null);
            await context.commit("setToken", null);
        },

        post(context, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.state.url + "api/" + payload.uri, payload.data, {
                        headers: {
                            Authorization: "Bearer" + " " + context.getters.token,
                        },
                    })
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                        // if(error?.response?.data?.message=='Unauthenticated.') {
                        // 	context.commit('setUser',null);
                        // 	router.push("/login");
                        // }
                    });
            });
        },

        async error(context, description) {
            await createToast(
                {
                    title: "Error",
                    description: description || "The given data was invalid.",
                },
                {
                    showIcon: true,
                    hideProgressBar: true,
                    type: "danger",
                    position: "top-right",
                }
            );
        },

        async success(context, description) {
            await createToast(
                {
                    title: "Success",
                    description: description || "Data is successfuly subbmited.",
                },
                {
                    showIcon: true,
                    hideProgressBar: true,
                    type: "success",
                    position: "top-right",
                }
            );
        },
    },
});

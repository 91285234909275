<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="breadcrumb-wrapper"><span class="text-muted fw-light">Configuration /</span> Handling Modes</h4>
        <div class="row">
            <div class="col-xl-4">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="mb-0" v-if="status">New Handling Mode</h4>
                        <h4 class="mb-0" v-else>Update Handling Mode</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm">
                            <div class="mb-3">
                                <label class="form-label">Handling Mode Name <span class="star">*</span></label>
                                <input class="form-control" type="text" placeholder="Handling Mode Name" ref="handling_mode_name" v-model="handling_mode.handling_mode_name" :class="{'is-invalid': errors.handling_mode_name}" />
                                <span v-if="errors.handling_mode_name" class="invalid-feedback">{{ errors.handling_mode_name[0] }}</span>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Description</label>
                                <textarea class="form-control" type="text" placeholder="Description" v-model="handling_mode.description"></textarea>
                                <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                            </div>
                            <div class="float-end">
                                <button type="button" @click="discard()" class="btn btn-label-danger me-2">Cancel</button>
                                <button type="submit" v-if="status" class="btn btn-primary">Submit</button>
                                <button type="submit" v-else class="btn btn-success">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-xl-8">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="mb-0">Handling Modes</h4>
                        <input class="form-control width-200" type="text" placeholder="Search" @keyup="search" v-model="meta.search" />
                    </div>
                    <div class="card-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-sm table-striped table-bordered">
                                <thead class="table-dark">
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th class="sorting" @click="sortTable('handling_mode_name')">Handling Mode Name</th>
                                        <th class="sorting" @click="sortTable('description')">Description</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr v-for="(handling_mode,key) in handling_modes" :key="key">
                                        <td class="text-center">{{ meta.from+key }}</td>
                                        <td>{{ handling_mode.handling_mode_name }}</td>
                                        <td>{{ handling_mode.description }}</td>
                                        <td class="text-center">
											<div class="form-switch">
												<input class="form-check-input" type="checkbox" v-model="handling_mode.status" :id="handling_mode.handling_mode_id" @change="deleteHandlingMode(handling_mode)" />
											</div>
										</td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm btn-icon btn-label-success me-1" @click="editHandlingMode(handling_mode)" title="Edit">
                                                <i class="bx bx-edit-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="handling_modes.length==0">
                                        <th class="text-center" colspan="5">No records found</th>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>Handling Mode</th>
                                        <th>Description</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <select class="form-control width-75" v-model="meta.per_page" @change="onPerPageChange">
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                            </select>
                            <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                status: true,
                handling_mode: {
                    handling_mode_id: "",
                    handling_mode_name:"",
                    description: "",
                },
                errors: [],
                handling_modes: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "handling_mode_name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                },
            };
        },
        mounted() {
            let vm = this;
            vm.discard();
        },
        methods: {
            discard() {
                let vm = this;
                vm.$refs.handling_mode_name.focus();
                vm.handling_mode.handling_mode_name = "";
                vm.handling_mode.description = "";
                vm.status = true;
                vm.errors = [];
                vm.index();
            },

            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateHandlingModes", data: vm.meta })
                .then(function (response) {
                    vm.handling_modes = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.to = response.data.meta.to;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            submitForm() {
				let vm = this;
				if(vm.status) {
					vm.addHandlingMode()
				}
				else {
					vm.updateHandlingMode()
				}
			},

            addHandlingMode() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "addHandlingMode", data: vm.handling_mode })
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "Handling Mode Added successfully");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            editHandlingMode(handling_mode) {
                let vm = this;
                vm.handling_mode = handling_mode;
                vm.status = false;
                vm.$refs.handling_mode_name.focus();
            },

            updateHandlingMode() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateHandlingMode", data: vm.handling_mode };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Handling Mode is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteHandlingMode(handling_mode) {
                let vm = this;
                let uri = { uri: "deleteHandlingMode", data: handling_mode };
                vm.$store.dispatch("post", uri)
                .then(function () {
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            sortTable(keyword) {
                let vm = this;
                vm.meta.keyword = keyword;
                vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc";
                vm.index();
            }
        }
    }
</script>

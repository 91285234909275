<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="breadcrumb-wrapper"><span class="text-muted fw-light">Profile </span></h4>
		<div class="card mb-4">
			<form class="card-body" @submit.prevent="updateProfile">
				<div class="row g-3 mb-3">
					<div class="col-md-3">
						<label class="form-label">Name <span class="star">*</span></label>
						<input type="text" class="form-control" placeholder="Name" :class="{'is-invalid': errors.name}" v-model="user.name" />
						<span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
					</div>
					<div class="col-md-3">
						<label class="form-label">Email <span class="star">*</span></label>
						<div class="input-group input-group-merge">
							<input type="text" class="form-control" :class="{'is-invalid': errors.email}" v-model="user.username" placeholder="Email" autofocus tabindex="1" />

							<span class="input-group-text" id="email">@adityabirla.com</span>
							<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
						</div>
					</div>
					<div class="col-md-3">
						<label class="form-label">Mobile No <span class="star">*</span></label>
						<input type="number" class="form-control" placeholder="Mobile No." :class="{'is-invalid': errors.mobile_no}" v-model="user.mobile_no" />
						<span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
					</div>
					<div class="col-md-3">
						<label class="form-label">Upload Profile Picture <span class="star">*</span></label>
						<input type="file" class="form-control" :class="{'is-invalid': errors.avatar}" @change="onImageChange($event)" ref="avatar" />
						<span class="form-text">Max Size : 2MB, Format : png, jpg, jpeg</span>
						<span v-if="errors.avatar" class="invalid-feedback">{{ errors.avatar[0] }}</span>
					</div>
				</div>
				<div class="float-end">
					<button type="submit" class="btn btn-primary">Update</button>
				</div>
			</form>
		</div>
    </div>
</template>

<script>
    export default {
        name: "Profile",
        data() {
            return {
                status: true,
                user: {
                    user_id: "",
                    name: "",
                    username: "",
                    email: "",
                    mobile_no: "",
                    avatar: "",
                },
                errors: [],
            };
        },

		mounted() {
			let vm = this;
			vm.user.user_id = vm.$store.getters.user.user_id;
			vm.user.name = vm.$store.getters.user.name;
			vm.user.username = vm.$store.getters.user.username;
			vm.user.mobile_no = vm.$store.getters.user.mobile_no;
			vm.user.avatar = vm.$store.getters.user.avatar;
		},

        methods: {
            updateProfile() {
                let vm = this;
                let loader = vm.$loading.show();
				let formData = new FormData();
                formData.append("user_id", vm.user.user_id);
				formData.append("name", vm.user.name);
				formData.append("username", vm.user.username);
				formData.append("email", vm.user.username + "@adityabirla.com");
				formData.append("mobile_no", vm.user.mobile_no);
				formData.append("avatar", vm.user.avatar);
				vm.$store.dispatch("post", { uri: "profile", data: formData })
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch("success", "Profile is successfully updated");
					vm.$store.commit("setUser", response.data.data);
					vm.$router.push("/dashboard");
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },
			
            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                vm.user.avatar = vm.$refs.avatar.files[0]
            },
        },
    };
</script>

<template>
    <nav class="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar" style="background-color: #4285F4 !important; color: #fff !important;">
        <div class="container-fluid">
            <div class="navbar-brand app-brand demo d-none d-xl-flex py-0 me-4">
                <router-link to="/" class="app-brand-link gap-2">
                    <span class="app-brand-logo ">
                        <img src="@/assets/logo.jpg" alt="Logo" height="40" />
                    </span>
                    <div class="text-white">
                        <span class="app-brand-text demo menu-text fw-bold d-block">UTCL - BCMS</span>
                        <small class="sub_title">Bag Complaint Management System</small>
                    </div>
                </router-link>
                <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
                    <i class="bx bx-x bx-sm align-middle"></i>
                </a>
            </div>

            <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                    <i class="bx bx-menu bx-sm"></i>
                </a>
            </div>

           <div class="navbar-nav-left d-flex align-items-center" id="navbar-collapse">
                <ul class="menu-inner  py-1">
                    <li class="menu-item">
                        <router-link to="/" class="menu-link">
                            <i class="menu-icon tf-icons bx bxs-home-circle"></i>
                            <div data-i18n="Dashboard">Dashboard</div>
                        </router-link>
                    </li>
                    <li class="menu-item dropdown" v-if="$store.getters.user.is_admin">
                        <a href="javascript:void(0)" class="menu-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown"> 
                            <i class="menu-icon tf-icons bx bxs-cog"></i>
                            <div data-i18n="Configuration">Configuration</div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" style="margin-top: 20px;">
                            <li>
                                <router-link to="/depots" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Depots</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/transportation_modes" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Transportation Modes</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/zones" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Zones</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/plants" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Plants</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/grades" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Grades</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/bag_types" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Bag Types</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/handling_modes" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Handling Modes</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/complaints" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Complaints</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/users" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Users</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/escalations" class="dropdown-item">
                                    <i class="bx bx-menu me-2"></i>
                                    <span class="align-middle">Escalation Matrix</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="menu-item" v-if="$store.getters.user?.role?.role_name=='TTSM' || $store.getters.user?.role?.role_name=='PL'">
                        <router-link to="/issues/create" class="menu-link">
                            <i class="menu-icon tf-icons bx bxs-message-square-add"></i>
                            <div data-i18n="NewIssue">New Issue</div>
                        </router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/issues" class="menu-link">
                            <i class="menu-icon tf-icons bx bxs-book-content"></i>
                            <div data-i18n="Issues">Issues</div>
                        </router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/report/issue" class="menu-link">
                            <i class="menu-icon tf-icons bx bxs-report"></i>
                            <div data-i18n="Report">Report</div>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <ul class="navbar-nav flex-row align-items-center ms-auto">
                    <li class="nav-item navbar-dropdown dropdown-user dropdown">
                        <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                            <div class="d-flex">
                                <div class="flex-grow-1 m-2">
                                    <span class="fw-semibold d-block lh-1">{{$store?.getters?.user?.name}}</span>
                                    <small class="float-end">{{$store?.getters?.user?.role?.role_name}}</small>
                                </div>
                                <div class="flex-shrink-0">
                                    <div class="avatar avatar-online">
                                        <img :src="$store.getters.user?.avatar" alt="Avatar" class="rounded-circle " />
                                    </div>
                                </div>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                                <a class="dropdown-item" href="#">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0 me-3">
                                            <div class="avatar avatar-online">
                                                <img :src="$store.getters.user?.avatar" alt="Avatar" class="rounded-circle " />
                                            </div>
                                        </div>
                                        <div class="flex-grow-1">
                                            <span class="fw-semibold d-block lh-1">{{$store?.getters?.user?.name}}</span>
                                             <small>{{$store?.getters?.user?.role?.role_name}}</small>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <div class="dropdown-divider"></div>
                            </li>
                            <li>
                                <router-link to="/profile" class="dropdown-item">
                                    <i class="bx bx-user me-2"></i>
                                    <span class="align-middle">My Profile</span>
                                </router-link>
                            </li>
                            <li>
                                <div class="dropdown-divider"></div>
                            </li>
                            <li>
                                <a @click.prevent="logout" class="dropdown-item" href="#">
                                    <i class="bx bx-power-off me-2"></i>
                                    <span class="align-middle">Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        data() {
            return {
                //active: true
                user:{
                    user_id:""
                }
            };
        },
        methods: {
            logout() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.user.user_id=vm.$store.getters.user.user_id;
                vm.$store.dispatch("auth", { uri: "logout", data: vm.user })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("logout");
                    location.reload()
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
        },
    };
</script>

<style scoped>
    .menu-item div{
        color: #fff !important;
        margin-right: 20px;
        font-weight: bold;
    }
    .menu-icon {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 0.3rem !important;
        font-size: 1.25rem;
        color: #fff;
    }

    .sub_title {
        font-size: 10px;
        font-weight: bold;
    }
</style>
